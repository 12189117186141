import axios from "axios";

// import { useLocation, useNavigate } from "react-router";
// const navigate = useNavigate();
const getPayload = (res, fieldName, defaultValue) =>
  res && res.data && res.data[fieldName] ? res.data[fieldName] : defaultValue;

const invokeAPI = async (
  dispatch,
  url,
  method,
  headerOptions,
  reducerType,
  fieldName,
  defaultValue,
  data,
  queryStrings,
  callback
) => {
  let params = {};
  if (queryStrings != null) {
    params = { ...queryStrings };
  }
  let authHeader = sessionStorage.getItem("accessToken");
  console.log(authHeader, "header");

  let headers = { Authorization: authHeader };

  headers = { ...headers, ...headerOptions, withCredentials: true };
  try {
    const res = await axios({
      url,
      method,
      data: data ? data : null,
      headers,
      params,
      withCredentials: true,
    });

    if (fieldName) {
      dispatch({
        type: reducerType,
        payload: getPayload(res, fieldName, defaultValue),
      });
    } else {
      dispatch({
        type: reducerType,
        payload: res,
      });
    }

    if (callback && typeof callback === "function") {
      callback(res.data);
    }
  } catch (err) {
    console.log(err, "err");

    if (err && err.response) {
      // console.error("err.response.data", err.response.data);
      console.log(err.response.data);
      // if ((err.response.status = 401 && err.response.data === "Unauthorized")) {
      //   window.location.replace("/session-expired");
      // }
      if (callback && typeof callback === "function")
        callback({ error: "Error Occured", data: err.response.data });
    } else {
      if (callback && typeof callback === "function") {
        callback({ error: "Error Occurred. please Contact Supported" });
      }
    }
    dispatch({ type: reducerType, payload: defaultValue });
  }
};

export default invokeAPI;
