import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@mui/system";
import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import theme from "../../theme";

const TextFieldContent = styled(TextField)`
  //   background-color: #red !important;
  & input {
    padding: 10px;
  }
`;
const Label = styled("div")`
  display: flex;
  flex-direction: row;
  color: #333c44;
  margin-bottom: 10px;
`;
const TextFieldComponent = (props) => {
  const { value, label, important, type } = props;
  return (
    <div>
      <Label>
        {label} {important ? <div style={{ color: "#e24c4c" }}>*</div> : null}
      </Label>
      <TextFieldContent
        fullWidth
        variant="filled"
        type={type || "text"}
        InputProps={{
          disableUnderline: true,
          sx: {
            backgroundColor: `${theme.textFieldColor}` + ` !important`,
          },
        }}
        value={value}
      ></TextFieldContent>
    </div>
  );
};

export default TextFieldComponent;
