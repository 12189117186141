const initialState = {
  response: null,
};

const snackbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET": {
      state.response = action.payload;
      return state;
    }
    case "CLEAR": {
      state.response = null;
      return state;
    }
    default:
      return state;
  }
};
export default snackbarReducer;
