const apiEndpoints = {
  BASE_URL: `${process.env.REACT_APP_BASE_URL}`,
  USER_LOGIN_URL: `${process.env.REACT_APP_BASE_URL}/api/v1/user-auth`,
  USER_LOGOUT_URL: `${process.env.REACT_APP_BASE_URL}/api/v1/logout`,
  //ADDRESS_VALIDATION_OAUTH_URL: `${process.env.REACT_APP_ADDRESS_VALIDATION_OAUTH_URL}`,
  ORDER_LIST_BY_STATUS: `${process.env.REACT_APP_BASE_URL}/orders`,
  ORDER_COUNT: `${process.env.REACT_APP_BASE_URL}/orders/count`,
  CUSTOMER_LIST: `${process.env.REACT_APP_BASE_URL}/orders/customers`,

  CITY_LIST: `${process.env.REACT_APP_BASE_URL}/orders/city`,
  STATE_PROVINCE_LIST: `${process.env.REACT_APP_BASE_URL}/orders/state`,
  COUNTRY_LIST: `${process.env.REACT_APP_BASE_URL}/orders/country`,

  UPDATE_ORDER_CATEGORY_STATUS: `${process.env.REACT_APP_BASE_URL}/orders/status/update`,

  CREATE_SO: `${process.env.REACT_APP_BASE_URL}/orders/create_so`,

  UPDATE_ORDERS: `${process.env.REACT_APP_BASE_URL}/orders/update`,
  
  //Comments endpoint:
  ADD_COMMENT: `${process.env.REACT_APP_BASE_URL}/comments`,
  GET_COMMENTS: `${process.env.REACT_APP_BASE_URL}/comments`,

};

module.exports = apiEndpoints;
