import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@mui/system";
import { useNavigate } from "react-router-dom";
import CallIcon from "@mui/icons-material/Call";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CheckIcon from "@mui/icons-material/Check";
import NearMeIcon from "@mui/icons-material/NearMe";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { handleCreateSO } from "../common/netsuite";
import Tooltip from "@mui/material/Tooltip";

//Imports by <pratik.sonar@rahisystems.com>
import { IconButton } from "@mui/material";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";

// Implement a function to validate SO Number: <pratik.sonar@rahisystems.com>
const validateSONumber = (SONumber) => {
  if (SONumber && SONumber !== "") return true;
  else return false;
};

const ActionComponent = (props) => {
  const { actions, row, handleReload } = props;
  const actionData = ["create_so"];
  const navigate = useNavigate();

  // const tooltipTitle = validateSONumber(row?.so_number)
  //   ? "Sales Order Created"
  //   : "Create Sales Order";

  const tooltipTitle = "Create Sales Order";

  return (
    // <div style={{ display: "flex" }}>
    <div>
      {actions &&
        actions.length &&
        actions.map((action, index) => {
          return (
            <div>
              {action === actionData[0] ? (
                <Tooltip // New implementation by <pratik.sonar@rahisystems.com>
                  disableFocusListener
                  disableTouchListener
                  title={tooltipTitle}
                  placement="top"
                  arrow
                >
                  {/* Added span to display tooltip for disable button */}
                  <span>
                    <IconButton
                      style={
                        {
                          // backgroundColor: "white",
                          // padding: "12px",
                          // margin: "0px 5px",
                          // color: "#2490ef",
                          // borderRadius: "5px",
                          // border: "1px solid #2490ef",
                          // height: "10px",
                          // display: "flex",
                          // alignItems: "center",
                        }
                      }
                      color="primary"
                      id="create_so"
                      onClick={async () => {
                        try {
                          // This function will create SO:
                          await handleCreateSO(row);

                          handleReload(); // This function will reload/refresh existing orders.
                        } catch (err) {
                          console.error("Error while creating Sales Order");
                        }
                      }}
                      //
                      // Disabling button a order who already have SO Number: <pratik.sonar@rahisystems.com>
                      disabled={validateSONumber(row?.so_number)}
                    >
                      {/* <Tooltip
                    disableFocusListener
                    disableTouchListener
                    title="Create Sales Order"
                    placement="top"
                    arrow
                  >
                    <span> */}
                      {/* {" "} */}
                      {/**Adding Span to enable tooltip even for disabled buttons */}
                      {/* <LibraryBooksIcon /> */}
                      <NoteAddOutlinedIcon style={{ fontSize: "26px" }} />
                      {/* </span>
                  </Tooltip> */}
                    </IconButton>
                  </span>
                </Tooltip>
              ) : null}
            </div>
          );
        })}
    </div>
  );
};

export default ActionComponent;
