import { OAUTH_TOKEN } from "../actions/actionTypes";
import initialState from "./initialState";

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case OAUTH_TOKEN:
      return {
        ...state,
        authToken: action.payload,
      };
    default:
      return state;
  }
}
