/**
 * Library of functions to interact with Netsuite APIs
 */
import { successToastOptions, errorToastOptions } from "../orders/constants";
import apiEndpoints from "../../config/config";
import axios from "axios";
import { toast } from 'react-toastify';

const getSO = async (data) => {
    try {
        return;

    } catch (err) {

    }
}

/**
 * Function to handle the SO creation and 
 * once successful update the orders table with returned SO internal ID and SO number 
 * and change the task states to work in progress and rahi's workflow_status to pending item fulfillment
 * and place the message in the pub/sub topic for responding back
 * Also, throw a success message to the user
 * 
 * If failed, throw the error message to the user 
 * and don't respond back to the pub/sub response topic
 * 
 * @param {Object} data 
 * @returns 
 */
export const handleCreateSO = async (data) => {

    console.log('handleCreateSO ', data)
    const loading = toast.loading('Creating SO')

    /**
     * Call Netsuite API to create SO
     */
    try {
        let authHeader = sessionStorage.getItem("accessToken");
        console.log(authHeader, "header");
        let headers = { Authorization: authHeader };

        const response = await axios({
            url: `${apiEndpoints.CREATE_SO}`,
            method: 'POST',
            data: data ? data : null,
            headers,
            withCredentials: true,
        });

        toast.update(loading, successToastOptions('Created Sales Order Successfully!'))

    }
    catch (err) {
        console.error('Unable to Create SO', err)

        toast.update(loading, errorToastOptions('Error while creating Sales Order!'))

    }
}



const isValidSO = (data) => {
    ;
}
