import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
// import { makeStyles, useTheme } from "@mui/styles";
import axios from "axios";
import { GoogleLogin } from "@react-oauth/google";
import GoogleButton from "react-google-button";
import useAuth from "../../hooks/useAuth";
import { connect } from "react-redux";
import { setUserRole, setUserDetail } from "../../actions/userAction";
import { USER_LOGIN_URL } from "../../config/config";
import CustomizedSnackbars from "../common/triggerSnacbar";
import { setSnackbarResponse } from "../../actions/snackbarActions";
// import loginScreen from "../utils/images/login-screen.png";
const Root = styled("div")`
  padding: 40px;
  // width: 1024px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
function LoginHooks(props) {
  const { setAuth, persist, setPersist, auth } = useAuth();
  console.log(persist, auth, "persist");
  const navigate = useNavigate();
  const {
    setSignedIn,
    setUserRole,
    role,
    setUserDetail,
    snackbarResponse,
    setSnackbarResponse,
  } = props;

  const handleLogout = async () => {
    setAuth({});
    try {
      await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/logout`, {
        withCredentials: true,
      });
      window && window.sessionStorage.removeItem("accessToken");
      window && window.sessionStorage.removeItem("user_id");
      window && window.localStorage.removeItem("persist");
    } catch (err) {
      console.error(err);
    }
  };

  const googleResponse = async (credentialResponse) => {
    // Check if a token was recieved and send it to our API:
    console.log(credentialResponse, "tyu");
    if (credentialResponse.credential) {
      const googleResponse = await axios.post(
        USER_LOGIN_URL,
        { token: credentialResponse.credential },
        { withCredentials: true }
      );
      // setResponse(googleResponse);
      setSnackbarResponse(googleResponse);

      if (googleResponse?.data?.name === "invalid_domain") handleLogout();

      if (googleResponse) {
        window.sessionStorage.setItem(
          "accessToken",
          `Bearer` + " " + googleResponse.data.token
        );

        setUserRole(googleResponse.data.role);
        setUserDetail(googleResponse.data);
        setPersist(true);
        const accessToken =
          googleResponse && googleResponse.data && googleResponse.data.token;
        setAuth({
          role: googleResponse.data.role,
          user_id: googleResponse.data.user_id,
          details: googleResponse.data,
          accessToken,
        });

        setTimeout(() => {
          navigate("/");
        }, 1000);
      }

      // window.sessionStorage.setItem("user_id", googleResponse.data.user_id);
      // navigate("/");
    }
  };

  const onFailure = (res) => {
    console.log("Login failed: res:", res);
  };

  useEffect(() => {
    // console.log("hi in useEffect persist");
    localStorage.setItem("persist", persist);
  }, [persist]);

  return (
    <Root>
      <div>
        {snackbarResponse?.data?.name === "invalid_domain" ? (
          <CustomizedSnackbars
            severity="error"
            messageText={"Unauthorized domain!"}
          />
        ) : null}
      </div>
      {/* <div className={classes.logoContent}>
            <img src={logo} alt="logo" className={classes.logoImage} />
          </div> */}

      <h2 id="title" style={{ margin: "20px" }}>
        Welcome to Yubico Shipment Dashboard
      </h2>
      <div>
        <GoogleLogin
          width="100%"
          size="large"
          id="googleLogin"
          logo_alignment="left"
          onSuccess={(credentialResponse) => googleResponse(credentialResponse)}
          onError={onFailure}
        />
      </div>
    </Root>
  );
}
function mapStateToProps(state) {
  console.log("Map state to props: ", state);
  console.log("return -> ", {
    role: state.userReducers.userRole || "",
    snackbarResponse: state.snackbarReducer.response || null,
  });
  return {
    role: state.userReducers.userRole || "",
    snackbarResponse: state.snackbarReducer.response || null,
  };
}

const mapDispatchToProps = {
  setUserRole,
  setUserDetail,
  setSnackbarResponse,
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginHooks);
