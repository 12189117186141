import React, { useEffect } from "react";
import { Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useAuth from "../../hooks/useAuth";

const Logout = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const handleLogout = async () => {
    setAuth({});
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/logout`,
        {
          withCredentials: true,
        }
      );
      window && window.sessionStorage.removeItem("accessToken");
      window && window.sessionStorage.removeItem("user_id");
      window && window.localStorage.removeItem("persist");
    } catch (err) {
      console.error(err);
    }
    navigate("/login");
  };
  return (
    <div>
      <Button variant="contained" onClick={handleLogout}>
        Logout
      </Button>
    </div>
  );
};

export default Logout;
