import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router";
import { makeStyles, useTheme } from "@mui/system";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import PrintIcon from "@mui/icons-material/Print";
import Button from "@mui/material/Button";
import ReactToPrint from "react-to-print";
import DetailsToPrint from "./print";
const Root = styled("div")`
  padding: 20px;
  //   width: 1024px;
  margin: auto;
  background-color: #f9fafa;
`;
const PrintPreview = styled("div")`
  padding: 40px;
  background-color: #e2e4e4;
`;
const PDFManipulationScreen = (props) => {
  console.log(props, "props");
  const { state } = useLocation();
  const navigate = useNavigate();
  console.log(state, "state===");
  const { order } = state;
  const [showUOM, setUOM] = useState(false);
  const handleUOM = (e) => {
    if (e.target.checked) {
      setUOM(true);
    } else {
      setUOM(false);
    }
  };
  let componentRef = useRef();
  return (
    <Root>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "1024px",
          margin: "auto",
        }}
      >
        <div>
          <h2 style={{ color: "#666" }}>{order.name}</h2>
        </div>
        <div>
          <Button
            onClick={() => {
              navigate("/orderDetails");
            }}
            variant="contained"
            sx={{
              // backgroundColor: "#1976d2",
              margin: "10px",
            }}
          >
            Back
          </Button>
          <ReactToPrint
            trigger={() => (
              <Button
                variant="contained"
              // sx={{
              //   backgroundColor: "#1976d2",
              //   color: "#fff",
              //   height: "40px",
              // }}
              >
                print
                <PrintIcon />
              </Button>
            )}
            content={() => componentRef}
          />
        </div>
      </div>

      <div style={{ display: "none" }}>
        <DetailsToPrint
          ref={(el) => (componentRef = el)}
          /* itemDetails={itemDetails}
          customerDetails={customerDetails}
           */order={order}
          showUOM={showUOM}
        />
      </div>

      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div style={{ height: "20px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox /> <p>Compact Item Print</p>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox value={showUOM} onChange={handleUOM} />{" "}
            <p>Print UOM after Quantity</p>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox /> <p>Print taxes with zero amount</p>
          </div>
        </div>
        <PrintPreview>
          <div
            style={{
              width: "600px",
              border: "1px solid black",
              backgroundColor: "#fff",
            }}
          >
            <DetailsToPrint
              /* itemDetails={itemDetails}
              customerDetails={customerDetails} */
              order={order}
              showUOM={showUOM}
            />
          </div>
        </PrintPreview>
      </div>
    </Root>
  );
};

export default PDFManipulationScreen;
