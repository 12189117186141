import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles, useTheme } from "@mui/system";
import { connect } from "react-redux";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableHead from "@mui/material/TableHead";
import Typography from "@mui/material/Typography";
import PrintIcon from "@mui/icons-material/Print";
import Paper from "@mui/material/Paper";
import { CircularProgress, IconButton, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import ReactToPrint from "react-to-print";
import DetailsToPrint from "./print";
import AddressComponent from "../common/addressComponent";
import CustomerDetails from "../common/customerDetailsComponent";
import ItemComponent from "../common/itemComponent";
import {
  addComment,
  getComments,
  updateOrderStatus,
} from "../../actions/orderAction";
import TimelineComponent from "../common/timelineComponent";
import Grid from "@mui/material/Grid";

import MUICard from "../MUICard/MUICard.component";

//Imports/conversations module:
import ForumIcon from "@mui/icons-material/Forum";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import ConversationCard from "../ConversationCard/ConversationCard.component";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { errorToastOptions, successToastOptions } from "./constants";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  margin: "20px",
  borderRadius: "12px",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", color: "#174a84" }} />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "white",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  color: "black",
  fontWeight: "bold",
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));
const Root = styled("div")`
  padding: 20px;
  // width: 1024px;
  margin: auto;
  background-color: #f9fafa;
`;
const TopSection = styled("div")`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const TextFieldContent = styled(TextField)`
  background-color: #f9fafa;
  & input {
    padding: 10px;
  }
`;
const Label = styled("div")`
  display: flex;
  flex-direction: row;
  color: #333c44;
  margin-bottom: 10px;
`;
const CustomerDetailsTab = styled("Paper")`
  display: flex;
  flex-direction: row;
  background-color: white;
  padding: 20px;
  margin: 20px;
  justify-content: flex-start;
`;

const Heading = styled("div")`
  font-size: 20px;
  font-weight: 500;
  margin: 10px;
`;

const StatusContainer = styled("div")`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px;
  background-color: #fff5f5;
  padding: 5px;
  border-radius: 5px;
`;
const ButtonContainer = styled("div")`
  display: flex;
  justify-content: flex-start;
  margin: 10px;
`;

function OrderDetails(props) {
  const { uuid } = useParams();
  const navigate = useNavigate();

  const { updateOrderStatus, orders, getComments, addComment } = props;
  const [order, setOrder] = useState("");

  //
  // Conversation Modal: <pratik.sonar@rahisystems.com>
  const [commentText, setCommentText] = useState("");
  const [isAddComment, setIsAddComment] = useState(false);
  const [comments, setComments] = useState([]);
  const [fetchingComments, setFetchingComments] = useState(false);
  //

  useEffect(() => {
    const filteredOrders = orders.filter((o) => (o.uuid === uuid ? o : ""));

    if (filteredOrders && filteredOrders.length) setOrder(filteredOrders[0]);
  }, []);

  const [expanded, setExpanded] = React.useState("addressDetails");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleSubmit = (selected) => {
    let payload = { id: uuid };
    if (selected === "address_verification") {
      payload.order_category_status = "verified_order";
    }
    if (selected === "verified_order") {
      payload.order_category_status = "verified_for_delivery";
    }
    if (selected === "verified_for_delivery") {
      payload.order_category_status = "delivery_notes";
    }
    if (selected === "delivery_notes") {
      payload.order_category_status = "outstanding_packing_slip";
    }
    if (selected === "outstanding_packing_slip") {
      payload.order_category_status = "completed_order";
    }
    updateOrderStatus(payload, (res) => {
      console.log(res, "res==");
      if (res.status === 200) {
        navigate("/");
      }
    });
  };

  // -----------Conversation-modal-handling-functionalities--------
  // -----------------<pratik.sonar@rahisystems.com>---------------

  useEffect(() => {
    setFetchingComments(true);
    getComments({ taskId: order.order_number }, (res) => {
      setComments(res.result);
      setFetchingComments(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  // <pratik.sonar@rahisystems.com>
  // Add comment:
  const handleSubmitComment = async () => {
    setIsAddComment(true);
    const loading = toast.loading("Sending Comment...");

    const commentBody = {
      number: order.order_number,
      additionalComments: commentText,
      workLog: "",
      state: "Work in Progress",
    };

    addComment(commentBody, ({ error, status, message }) => {
      if (status === "SUCCESS") {
        message = "Sent Successfully";
        toast.update(loading, successToastOptions(message));
        setCommentText("");
        setIsAddComment(false);
      } else {
        toast.update(loading, errorToastOptions(error));
      }
    });
  };
  //

  return (
    <div style={{ backgroundColor: "#f9fafa" }}>
      <Root>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={() => {
              navigate("/");
            }}
            variant="contained"
            sx={{
              margin: "10px",
            }}
            id="back"
          >
            Back
          </Button>
          <Button
            onClick={() => {
              navigate("/pdf", {
                state: {
                  //itemDetails,
                  order,
                },
              });
            }}
            variant="contained"
            sx={{
              margin: "10px",
            }}
            id="print"
          >
            Print
          </Button>
          {/* <Button
            // disabled
            onClick={() => {
              handleSubmit("address_verification");
            }}
            variant="contained"
            sx={{
              margin: "10px",
            }}
            id="submit"
          >
            Submit
          </Button> */}
        </div>

        <TopSection>
          <Heading>{order?.ritm_request?.number}</Heading>
          <StatusContainer>
            <div
              style={{
                width: "5px",
                height: "5px",
                borderRadius: "50%",
                backgroundColor: "#e24c4c",
              }}
            ></div>
            <div
              style={{
                fontSize: "10px",
                margin: "5px",
              }}
            >
              {order?.order_number}
            </div>
          </StatusContainer>
        </TopSection>
        <div>
          <CustomerDetailsTab>
            <CustomerDetails order={order} />
          </CustomerDetailsTab>

          <Accordion
            id="addressDetails"
            expanded={expanded === "addressDetails"}
            onChange={handleChange("addressDetails")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography sx={{ fontWeight: "bold" }}>
                Address and Contact
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AddressComponent order={order} />
            </AccordionDetails>
          </Accordion>

          {/* 
          <Accordion
            id="workNotes"
            expanded={expanded === "workNotes"}
            onChange={handleChange("workNotes")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography sx={{ fontWeight: "bold" }}>
                Work Log
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={4}>
                  <TimelineComponent custom_data={order.worknotes} />
                </Grid>
                <Grid item xs={2}>
                </Grid>
                <Grid item xs={4}>
                  <TimelineComponent custom_data={order.comments} />
                </Grid>

              </Grid>
            </AccordionDetails>
          </Accordion> */}
        </div>
        <ItemComponent order={order} />

        {/* Author: Pratik Sonar <pratik.sonar@rahisystems.com> */}
        {/* Conversations Module: Temporary removed  */}
        {/* <div style={{ padding: "16px" }}>
          <MUICard
            removeBorder
            style={{ borderRadius: 0 }}
            title={"Conversations"}
            icon={<ForumIcon />}
          >
            {comments.length > 0 ? (
              comments.map((comment) => (
                <div key={comment.UUID}>
                  <ConversationCard
                    displayName={comment.createdBy.name}
                    email={comment.createdBy.email}
                    subtitle={comment.createdOn}
                    message={comment.value}
                  />
                  <div style={{ height: "12px" }}></div>
                </div>
              ))
            ) : (
              <div style={{ width: "100%", textAlign: "center" }}>
                {fetchingComments ? (
                  <CircularProgress />
                ) : (
                  <Typography>No Comments Available</Typography>
                )}
              </div>
            )}
          </MUICard>
          <MUICard removeBorder style={{ borderRadius: 0 }}>
            <Grid spacing={1} container>
              <Grid sm={11} item>
                <TextField
                  value={commentText}
                  onChange={(e) => setCommentText(e.target.value)}
                  size="small"
                  placeholder="Add comment..."
                  fullWidth
                  multiline
                  disabled={isAddComment}
                />
              </Grid>
              <Grid sm={1} item>
                <LoadingButton
                  size="large"
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="button"
                  onClick={handleSubmitComment}
                  loading={isAddComment}
                  disabled={commentText ? false : true}
                >
                  <SendIcon />
                </LoadingButton>
              </Grid>
            </Grid>
          </MUICard>
        </div> */}

        {/* endl */}
      </Root>
    </div>
  );
}
function mapStateToProps(state) {
  const orders = state.orderReducers.selectedData || [];
  console.log("orders in orderDetails page is ", orders);

  return { orders };
}
const mapDispatchToProps = {
  updateOrderStatus,
  getComments,
  addComment,
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
