import {
  ORDERS_BY_STATUS,
  ORDERS_COUNT,
  GET_CUSTOMER_LIST,
} from "./actionTypes";
import invokeAPI from "../config/apiConfiguration";
import apiEndpoints from "../config/config";
import {
  successToastOptions,
  errorToastOptions,
} from "../components/orders/constants";

import { toast } from "react-toastify";

/**
 * Redux Action Function to fetch the desired data from the backend APIs and load the reducer
 * @param {Number} selectedStatus
 * @param Number status
 * @param {String} customer
 * @param {DateString} startDate
 * @param {DateString} endDate
 * @param {Number} limit
 * @param {Number} offset
 * @returns
 */

export const getOrderListByStatus =
  (
    selectedStatus,
    task_states,
    regions,
    yubikey_types,

    customer,
    city,
    state_province,
    country_1,
    startDate,
    endDate,
    limit,
    offset,

    //
    //
    searchType,
    searchString
  ) =>
  (dispatch) => {
    console.log("getOrderListByStatus called");

    const loading = toast.loading("Loading");

    let query = {
      order_by_wflow_status: selectedStatus, //Rahi's Internal wflw_status
      task_states: task_states && task_states.length ? task_states : undefined, //Apple's Task State
      //"regions": regions && regions.length ? regions/* .join()  */ : undefined,
      yubikey_types:
        yubikey_types && yubikey_types.length
          ? yubikey_types /* .join() */
          : undefined, //Apple's Task State

      customer: customer && customer.length ? customer : undefined, // Name of the customer who ordered

      city: city && city.length ? city : undefined, // Name of the customer who ordered
      state_province:
        state_province && state_province.length ? state_province : undefined, // Name of the customer who ordered
      country_1: country_1 && country_1.length ? country_1 : undefined, // Name of the customer who ordered

      startDate: startDate ? startDate : undefined, //Start Date Range of the request received by Rahi
      endDate: endDate ? endDate : undefined, //End Date Range of the request received by Rahi
      limit: limit, //Table's Pagination row limit
      offset: offset, // Table's Pagination offset value

      //
      //
      searchType,
      searchString,
    };

    const callback = (data) => {
      if (data.error) {
        toast.update(loading, errorToastOptions("Error while loading!"));
      } else {
        toast.update(loading, successToastOptions("Loaded Successfully!"));
      }
    };

    invokeAPI(
      dispatch,
      `${apiEndpoints.ORDER_LIST_BY_STATUS}`,
      "GET",
      null,
      ORDERS_BY_STATUS,
      "orders",
      [],
      null,
      query,
      callback
    );
  };

/**
 * Redux Action Component to fetch the orders count based on the criteria
 * @returns
 */
export const getOrdersCount = (query) => (dispatch) => {
  invokeAPI(
    dispatch,
    `${apiEndpoints.ORDER_COUNT}`,
    "GET",
    null,
    ORDERS_COUNT,
    "ordersCount",
    [],
    null,
    query,
    null
  );
};

/**
 * Function to update the order status
 * @param {Object} values
 * @param {function} cb
 * @returns
 */
export const updateOrderStatus = (values, cb) => (dispatch) => {
  invokeAPI(
    dispatch,
    `${apiEndpoints.UPDATE_ORDER_STATUS}`,
    "POST",
    null,
    null,
    null,
    [],
    values,
    null,
    cb
  );
};

/**
 * Function to update the details of the orders
 * @param {Object} values
 * @param {function} cb
 * @returns
 */
export const updateOrders = (values, cb) => (dispatch) => {
  console.log("updateOrders function is triggered");

  invokeAPI(
    dispatch,
    `${apiEndpoints.UPDATE_ORDERS}`,
    "POST",
    null,
    null,
    null,
    [],
    values,
    null,
    cb
  );
};

//A function to add comment
export const addComment = (values, cb) => (dispatch) => {
  console.log("add comment function is called");

  invokeAPI(
    dispatch,
    `${apiEndpoints.ADD_COMMENT}`,
    "POST",
    null,
    null,
    null,
    [],
    values,
    null,
    cb
  );
};

//Implement a function to fetch comments by task ID:
export const getComments = (values, cb) => (dispatch) => {
  console.log("add comment function is called");

  invokeAPI(
    dispatch,
    `${apiEndpoints.GET_COMMENTS}/${values.taskId}`,
    "GET",
    null,
    null,
    null,
    [],
    values,
    null,
    cb
  );
};
