export const setSnackbarResponse = (payload) => (dispatch) => {
  dispatch({
    type: "SET",
    payload,
  });
};

export const clearSnackbarResponse = () => (dispatch) => {
  dispatch({
    type: "CLEAR",
  });
};
