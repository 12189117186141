import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePickerRange = (props) => {
  const {
    startDateFilter,
    setStartDateFilter,
    endDateFilter,
    setEndDateFilter,
  } = props;
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  return (
    <div style={{ display: "flex", justifyContent: "space-evenly" }}>
      <div>
        <DatePicker
          selected={startDateFilter}
          onChange={(date) => setStartDateFilter(date)}
          selectsStart
          placeholderText="StartDate"
          startDate={startDateFilter}
          endDate={endDateFilter}
          wrapperClassName="datePicker"
        />
      </div>
      <div style={{ margin: "0px 12px" }}>To</div>
      <div>
        <DatePicker
          selected={endDateFilter}
          placeholderText="EndDate"
          onChange={(date) => setEndDateFilter(date)}
          selectsEnd
          startDate={startDateFilter}
          endDate={endDateFilter}
          minDate={startDateFilter}
          wrapperClassName="datePicker"
        />
      </div>
    </div>
  );
};

export default DatePickerRange;
