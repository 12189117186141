const headCells = {
  1: [
    {
      id: "order_number",
      label: "Apple Task ID",
      showlabel: "Apple Task ID",
    },
    {
      id: "additionalcommentsStr",
      label: "Additional Comments",
      showlabel: "Additional Comments",
    },
    {
      id: "ritm_id",
      label: "RITM ID",
      showlabel: "RITM ID",
    },
    {
      id: "yubikey_type",
      label: "Yubikey Type",
      showlabel: "Type",
    },
    {
      id: "qty",
      label: "Qty",
      showlabel: "Qty",
    },
    {
      id: "req_loaded_in_db_utc",
      label: "Received Date",
      showlabel: "Requested Date",
    },
    {
      id: "name",
      label: "Full Name",
      showlabel: "Name",
    },
    /* {
      id: "phone",
      label: "Phone Number",
      showlabel: "Phone Number",
    }, */
    /* {
      id: "address",
      label: "Address",
      showlabel: "Address",
    },
    {
      id: "address_2",
      label: "Address 2",
      showlabel: "Address 2",
    },
    {
      id: "city",
      label: "City",
      showlabel: "City",
    },
    {
      id: "state_province",
      label: "State / Province",
      showlabel: "State / Province",
    },
    {
      id: "zipcode",
      label: "Zipcode",
      showlabel: "Zipcode",
    },
    {
      id: "country_1",
      label: "Country",
      showlabel: "Country",
    }, */
    {
      id: "full_address",
      label: "Full Address",
      showlabel: "Full Address",
    },
    {
      id: "title",
      label: "Title",
      showlabel: "Title",
    },
    {
      id: "description",
      label: "Description",
      showlabel: "Description",
    },
    {
      id: "so_number",
      label: "SO Number",
      showlabel: "SO Number",
    },

    {
      id: "if_number",
      label: "IF Number",
      showlabel: "IF Number",
    },
    {
      id: "tracking_number",
      label: "Tracking Number",
      showlabel: "Tracking Number",
    },

    {
      id: "delivered_to_local_entity",
      label: "Delivered to Local Entity",
      showlabel: "Delivered to Local Entity",
    },

    {
      id: "international_tracking_number",
      label: "International Tracking Number",
      showlabel: "International Tracking Number",
    },

    {
      id: "serial_numbers",
      label: "Serial Numbers",
      showlabel: "Serial Numbers",
    },

    {
      id: "internal_memo",
      label: "Internal Memo",
      showlabel: "Internal Memo",
    },

    /* {
      id: "workNotesStr",
      label: "Work Notes",
      showlabel: "Work Notes",
    }, */

    {
      // id: "dateShippedUTC",
      id: "date_shipped",
      label: "Date Shipped (MM/DD/YYYY)",
      showlabel: "Date Shipped (MM/DD/YYYY)",
    },

    {
      id: "is_completed",
      label: " Completed",
      showlabel: "Completed",
    },

    {
      id: "invalid_address",
      label: "Invalid Address",
      showlabel: "Invalid Address",
    },

    {
      id: "is_cancelled",
      label: "Cancelled",
      showlabel: "Cancelled",
    },
  ],
};

const country_codes = {
  Albania: "AL",
  Afghanistan: "AF",
  Algeria: "DZ",
  "American Samoa": "AS",
  Andorra: "AD",
  Angola: "AO",
  Anguilla: "AI",
  "Antigua, Barbuda": "AG",
  Argentina: "AR",
  Armenia: "AM",
  Aruba: "AW",
  Australia: "AU",
  Austria: "AT",
  Azerbaijan: "AZ",
  Bahamas: "BS",
  Bahrain: "BH",
  Bangladesh: "BD",
  Barbados: "BB",
  Belarus: "BY",
  Belgium: "BE",
  Belize: "BZ",
  Benin: "BJ",
  Bermuda: "BM",
  Bhutan: "BT",
  Bolivia: "BO",
  "Bosnia-Herzegovina": "BA",
  Botswana: "BW",
  Brazil: "BR",
  Brunei: "BN",
  Bulgaria: "BG",
  "Burkina Faso": "BF",
  Burundi: "BI",
  Cambodia: "KH",
  Cameroon: "CM",
  Canada: "CA",
  "Cape Verde": "CV",
  Chad: "TD",
  Chile: "CL",
  "China, People Republic Of": "CN",
  Colombia: "CO",
  Congo: "CG",
  "Congo, Dem Rep Of": "CD",
  "Cook Islands": "CK",
  "Costa Rica": "CR",
  Croatia: "HR",
  Curacao: "CW",
  Cyprus: "CY",
  "Czech Republic": "CZ",
  Denmark: "DK",
  Djibouti: "DJ",
  Dominica: "DM",
  "Dominican Republic": "DO",
  "East Timor": "TL",
  Ecuador: "EC",
  Egypt: "EG",
  "El Salvador": "SV",
  "England, Great Britain, Northern Ireland, Scotland, United Kingdom, Wales, Channel Islands":
    "GB",
  Eritrea: "ER",
  Estonia: "EE",
  Ethiopia: "ET",
  "Faeroe Islands": "FO",
  Fiji: "FJ",
  Finland: "FI",
  France: "FR",
  "French Guiana": "GF",
  Gabon: "GA",
  Gambia: "GM",
  "Georgia, Republic Of": "GE",
  Germany: "DE",
  Ghana: "GH",
  "Grand Cayman, Cayman Islands": "KY",
  Gibraltar: "GI",
  "Great Thatch Island, Great Tobago Islands, Jost Van Dyke Islands, Norman Island, Tortola Island, British Virgin Islands":
    "VG",
  Greece: "GR",
  Greenland: "GL",
  Grenada: "GD",
  Guadeloupe: "GP",
  Guam: "GU",
  Guatemala: "GT",
  Guinea: "GN",
  "Guinea Bissau": "GW",
  Guyana: "GY",
  Haiti: "HT",
  Honduras: "HN",
  "Hong Kong, SAR, China": "HK",
  Hungary: "HU",
  Iceland: "IS",
  India: "IN",
  Indonesia: "ID",
  Iraq: "IQ",
  "Northern Republic Of Israel": "IL",
  "Ivory Coast": "CI",
  Jamaica: "JM",
  Japan: "JP",
  Jordan: "JO",
  Kazakhstan: "KZ",
  Kenya: "KE",
  "Korea, South (South Korea)": "KR",
  Kuwait: "KW",
  Kyrgyzstan: "KG",
  Laos: "LA",
  Latvia: "LV",
  Lebanon: "LB",
  Lesotho: "LS",
  Liberia: "LR",
  Libya: "LY",
  Liechtenstein: "LI",
  Lithuania: "LT",
  Luxembourg: "LU",
  "Macau SAR, China": "MO",
  Macedonia: "MK",
  Madagascar: "MG",
  Malawi: "MW",
  Malaysia: "MY",
  "Maldives, Republic Of": "MV",
  Mali: "ML",
  Malta: "MT",
  "Marshall Islands": "MH",
  Martinique: "MQ",
  Mauritania: "MR",
  Mauritius: "MU",
  Mexico: "MX",
  Micronesia: "FM",
  Moldova: "MD",
  Monaco: "MC",
  Mongolia: "MN",
  Montserrat: "MS",
  Morocco: "MA",
  Mozambique: "MZ",
  Namibia: "NA",
  Nepal: "NP",
  "Netherlands (Holland)": "NL",
  "Netherlands Antilles (Caribbean)": "AN",
  "New Caledonia": "NC",
  "New Guinea, Papua New Guinea": "PG",
  "New Zealand": "NZ",
  Nicaragua: "NI",
  Niger: "NE",
  Nigeria: "NG",
  Norway: "NO",
  Oman: "OM",
  Pakistan: "PK",
  Palau: "PW",
  Panama: "PA",
  Paraguay: "PY",
  Peru: "PE",
  Philippines: "PH",
  Poland: "PL",
  "Portugal, Azores, Madeira": "PT",
  "Puerto Rico": "PR",
  Qatar: "QA",
  "Reunion Island": "RE",
  Romania: "RO",
  "Rota, Tinian, Saipan": "MP",
  Russia: "RU",
  Rwanda: "RW",
  "Bonaire, Caribbean Netherlands, Saba, St. Eustatius": "BQ",
  "Saudi Arabia": "SA",
  Senegal: "SN",
  Serbia: "RS",
  Seychelles: "SC",
  Singapore: "SG",
  "Slovak Republic": "SK",
  Slovenia: "SI",
  "South Africa, Republic Of": "ZA",
  "Spain, Canary Islands": "ES",
  "Sri Lanka": "LK",
  "St. Barthelemy": "BL",
  "Nevis, St. Christopher, St. Kitts And Nevis": "KN",
  "St. Croix Island, St. John, St. Thomas": "VI",
  "St. Lucia": "LC",
  "St. Maarten": "SX",
  "St. Martin": "MF",
  "St. Vincent, Union Island": "VC",
  Suriname: "SR",
  Swaziland: "SZ",
  Sweden: "SE",
  Switzerland: "CH",
  "Tahiti, French Polynesia": "PF",
  "Taiwan, China": "TW",
  Tanzania: "TZ",
  Thailand: "TH",
  Togo: "TG",
  Tonga: "TO",
  "Trinidad and Tobago": "TT",
  Tunisia: "TN",
  Turkey: "TR",
  Turkmenistan: "TM",
  "Turks and Caicos Islands": "TC",
  Tuvalu: "TV",
  Uganda: "UG",
  Ukraine: "UA",
  "United Arab Emirates": "AE",
  "United States": "US",
  Uruguay: "UY",
  Uzbekistan: "UZ",
  Vanuatu: "VU",
  "Vatican City, San Marino, Italy": "IT",
  Venezuela: "VE",
  Vietnam: "VN",
  "Wallis and Futuna Islands": "WF",
  Zambia: "ZM",
  Zimbabwe: "ZW",
};

const tableActions = {
  1: ["create_so" /* , "attach_so" */],
};

const centerAlignColumns = [
  "yubikey_type",
  "ritm_id",
  "qty",
  "city",
  "state_province",
  /* 'zipcode',
  'region',
  'country', */
  "city",
  "req_loaded_in_db_utc",
  "task_state",
  "invalid_address",
  "is_completed",
];

const stickyColumn = ["order_number"];

const successToastOptions = (msg) => {
  return {
    isLoading: false,
    type: "success",
    render: msg,
    autoClose: 3000,
    hideProgressBar: true,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    theme: "light",
  };
};

const errorToastOptions = (msg) => {
  return {
    isLoading: false,
    type: "error",
    render: msg,
    autoClose: 3000,
    hideProgressBar: true,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    theme: "light",
  };
};

const filters = [
  //  "task_states",
  "yubikey_types",
  //"regions",
  "city",
  "state_province",
  "country_1",
  "name",
  "req_loaded_in_db_utc",
];

const dateFilterOptions = [
  { id: 1, name: "Past 6 months" },
  { id: 2, name: "Past 3 months" },
  { id: 3, name: "Custom" },
];

/**Database configs */

const taskStatesFilterOptions = [
  { id: 1, name: "Open" },
  { id: 2, name: "Work in Progress" },
  { id: 3, name: "Cancelled" },
  { id: 4, name: "Completed" },
];

const regionsFilterOptions = [
  { id: 1, name: "AMR" },
  { id: 2, name: "APAC" },
  { id: 3, name: "EMEIA" },
];

const yubikeyTypesFilterOptions = [
  { id: 1, name: "USB-A" },
  { id: 2, name: "USB-C" },
];

const wflow_status = {
  "Pending Sales Order": 1,
  //'Pending Address Verification': 2,
  //'Pending Item Fulfillment': 3,
  "Pending Tracking Numbers": 4,
  "Pending Completion": 5,
  Completed: 6,
  Cancelled: 7,
  "Pending Cancellation": 8,
  all_active: 9, //this wont be actual workflow state. It is only for filtering the details
};

module.exports = {
  headCells,
  country_codes,
  tableActions,
  centerAlignColumns,
  successToastOptions,
  errorToastOptions,

  filters,

  dateFilterOptions,

  taskStatesFilterOptions,
  regionsFilterOptions,
  yubikeyTypesFilterOptions,
  wflow_status,
  stickyColumn,
};
