import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';
import moment from 'moment';

export default function TimelineComponent(props) {

    const [data, setData] = React.useState(/* [
        {
            "UUID": "2e38edc6ebd66110830cb0590831df93",
            "value": "Test Log post\\n :: Updated by the user ::kentaurus bot.\\n",
            "record": "TASK001534385",
            "createdBy": {
                "vip": false,
                "UUID": "0485d3a1c39e1200e4868d4fed5d7526",
                "dsid": "973599789",
                "name": "Loys Amalraj",
                "email": "loys@apple.com"
            },
            "createdOn": "2023-04-20T21:09:52.000Z"
        },
        {
            "UUID": "504e694aeb9e21107668b0590831df2e",
            "value": "Test Log post\\n :: Updated by the user ::kentaurus bot.\\n",
            "record": "TASK001534385",
            "createdBy": {
                "vip": false,
                "UUID": "0485d3a1c39e1200e4868d4fed5d7526",
                "dsid": "973599789",
                "name": "Loys Amalraj",
                "email": "loys@apple.com"
            },
            "createdOn": "2023-04-20T21:36:12.000Z"
        },
        {
            "UUID": "69f2750eeb9e21106dfeb0590831df96",
            "value": "Test Log post\\n :: Updated by the user ::kentaurus bot.\\n",
            "record": "TASK001534385",
            "createdBy": {
                "vip": false,
                "UUID": "0485d3a1c39e1200e4868d4fed5d7526",
                "dsid": "973599789",
                "name": "Loys Amalraj",
                "email": "loys@apple.com"
            },
            "createdOn": "2023-04-20T21:56:46.000Z"
        },
        {
            "UUID": "3e33bdcaeb9e2110766837490831df4d",
            "value": "Test Log cloud function\\n :: Updated by the user ::kentaurus bot.\\n",
            "record": "TASK001534385",
            "createdBy": {
                "vip": false,
                "UUID": "0485d3a1c39e1200e4868d4fed5d7526",
                "dsid": "973599789",
                "name": "Loys Amalraj",
                "email": "loys@apple.com"
            },
            "createdOn": "2023-04-20T21:57:58.000Z"
        },
        {
            "UUID": "1fd4f9ceebd66110830cb0590831dff3",
            "value": "Test Log post\\n :: Updated by the user ::kentaurus bot.\\n",
            "record": "TASK001534385",
            "createdBy": {
                "vip": false,
                "UUID": "0485d3a1c39e1200e4868d4fed5d7526",
                "dsid": "973599789",
                "name": "Loys Amalraj",
                "email": "loys@apple.com"
            },
            "createdOn": "2023-04-20T22:05:05.000Z"
        }
    ] */[])

    React.useEffect(() => {
        console.log('props ', props)
        if (props?.custom_data && props.custom_data.length > 0) {
            setData(props.data)
        }
    }, [props.custom_data])

    return (
        <Timeline position="right">

            {data && data.map(d => {

                const loggedDateTime = moment(d.createdOn).local().format("MM/DD/YYYY HH:mm")

                const index = d?.value.indexOf("\\n");
                const substring = d?.value.substring(0, index);
                return (
                    <TimelineItem>
                        <TimelineOppositeContent
                            sx={{ m: 'auto 0' }}
                            align="right"
                            variant="body2"
                            color="text.secondary"
                        >
                            {loggedDateTime}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot>
                                <FastfoodIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Typography variant="h6" component="span">
                                {d.createdBy.name}
                            </Typography>
                            <Typography>{substring}</Typography>
                        </TimelineContent>
                    </TimelineItem>
                )
            }
            )}
        </Timeline>
    );
}
