import React, { useState, useEffect, useCallback } from 'react';
import TextField from '@mui/material/TextField';
import debounce from 'lodash/debounce';
import { Pink } from '@mui/material/colors'

/**
 * 
 * @param {Object} props - props that are passed to this component
 * @param {String} props.value - Value of this text field
 * @param {String} props.textFieldLabel - Name for this text field
 * @param {String} props.defaultValue - Default value of the text field
 * @param {Object} props.helperTextValue - which is used to give context about a field's input, such as how the input will be used.
 * @param {bool} props.isRequired - Indicate whether the label is indicating that the input is required input, inherited from the FormControl
 * @param {bool} props.isReadOnly - Indicate whether the component is being displayed in a disabled state, inherited from FormControl disabled prop.
 * @param {bool} props.isMultiLine - Indicate whether the field is a multiline text field
 * @param {Function} props.startAdornment - This can be used to add a prefix or an action to an input. For instance, you can use an icon button to hide or reveal the password.
 * @param {Function} props.endAdornment - This can be used to add a suffix or an action to an input. For instance, you can use an icon button to hide or reveal the password.
 * @param {Function} props.handleTextValue - Setter Function from the parent component to set the text field input text
 * @returns 
 */
export default function TextBox(props) {

    const { id, field, value, componentId, textFieldLabel, defaultValue, helperTextValue, isRequired, isReadOnly, isMultiLine, startAdornment, endAdornment, handleTextValue, type, InputProps, ...remaining } = props

    // Set the identifier for the text field based on the textFieldLabel prop 
    const componentID = `text-Fields-${componentId}`

    /*  const handleChange = (event) => {
         handleTextValue(id, { [field]: event.target.value })
     }; */

    const [inputValue, setInputValue] = useState(props.value);

    const handleTextChange = useCallback(
        debounce((event) => {
            const textValue = event.target.value;
            props.handleTextValue(props.id, {
                [props.field]: textValue,
                order_number: props.order_number,
                order: props.order
            });
        }, 500),
        [] // This empty array ensures that the debounce function is only created once
    );

    const handleChange = (event) => {
        setInputValue(event.target.value);
        handleTextChange(event);
    };

    return (
        <TextField
            id={componentID}
            value={inputValue}
            label={textFieldLabel}
            defaultValue={defaultValue}
            helperText={helperTextValue}
            variant="outlined"
            multiline={isMultiLine}
            onChange={handleChange}
            required={isRequired}
            type={type}
            InputProps={InputProps}

            // InputProps={{
            //     ...InputProps,
            //     style: {
            //         // color: !value ? 'red' : 'blue',
            //         borderColor: !value ? 'red' : 'black',
            //         border: "1px solid"
            //     }
            // }}

            startadornment={startAdornment}
            endadornment={endAdornment}
            {...remaining}
        />
    );
}