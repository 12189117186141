export const OAUTH_TOKEN = "OAUTH_TOKEN";
export const ADDRESS_VALIDATION = "ADDRESS_VALIDATION";
export const USER_ROLE = "USER_ROLE";
export const CURRENT_USER_ROLE = "CURRENT_USER_ROLE";
export const USER_DETAILS = "USER_DETAILS";
export const All_USERS = "All_USERS";
export const ORDERS_BY_STATUS = "ORDERS_BY_STATUS";
export const ORDERS_COUNT = "ORDERS_COUNT";
export const GET_CUSTOMER_LIST = "GET_CUSTOMER_LIST";
export const GET_CITY_LIST = "GET_CITY_LIST";
export const GET_STATE_PROVINCE_LIST = "GET_STATE_PROVINCE_LIST";
export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST";
