import React from "react";
import { styled } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";

const PrintHeading = styled("div")`
  text-align: right;
  text-transform: uppercase;
  color: #666;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #d1d8dd;
`;

const StatusHead = styled("h3")`
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  //   font-size: 10px;
`;
export default class DetailsToPrint extends React.Component {
  render() {
    return (
      <div style={{ padding: "30px" }}>
        <PrintHeading>
          <h2 style={{ margin: "0px" }}>SALES ORDER</h2>
          <h4 style={{ margin: "0px" }}>{this.props.order.name}</h4>
        </PrintHeading>
        <div>
          <StatusHead>{this.props.order.status || 'TEMP STATUS'}</StatusHead>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              //   justifyContent: "space-around",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "10px",
                width: "50%",
              }}
            >
              <table>
                <tbody>
                  <tr>
                    <td align="left">
                      <b>Customer Name:</b>
                    </td>
                    <td align="left">
                      {this.props.order.name}
                    </td>
                  </tr>
                  <br />
                  <tr>
                    <td align="left">
                      <b>Customer Country:</b>
                    </td>
                    <td align="left">
                      {this.props.order.country}
                    </td>
                  </tr>
                  <br />
                  <tr>
                    <td align="left">
                      <b>Address:</b>
                    </td>
                    <td align="left">
                      {this.props.order.shipping_address.replace(
                        /<br>/g,
                        ""
                      )}
                    </td>
                  </tr>
                  <br />
                  <tr>
                    <td align="left">
                      <b>Contact:</b>
                    </td>
                    <td align="left">
                      {this.props.order.name}
                    </td>
                  </tr>
                  <br />
                  <tr>
                    <td align="left">
                      <b>Phone:</b>
                    </td>
                    <td align="left">
                      {this.props.order.phone}
                    </td>
                  </tr>
                  <br />
                  <tr>
                    <td align="left">
                      <b>Mobile No:</b>
                    </td>
                    <td align="left">
                      {this.props.order.phone}
                    </td>
                  </tr>
                  <br />
                </tbody>
              </table>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "10px",
                width: "50%",
              }}
            >
              <table>
                <tbody>
                  <tr>
                    <td align="left" width="100px">
                      <b>Date:</b>
                    </td>
                    <td align="left">
                      {this.props.order.transaction_date || ''}
                    </td>
                  </tr>
                  <br />
                  <tr>
                    <td align="left">
                      <b>Delivery Date:</b>
                    </td>
                    <td align="left">
                      {this.props.order.delivery_date || ''}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div>
          <table
            style={{
              border: "1px solid black",
              borderCollapse: "collapse",
            }}
          >
            <thead
              style={{
                border: "1px solid black",
                borderCollapse: "collapse",
                background: "#eee",
                fontSize: "10px",
              }}
            >
              <tr>
                <th
                  style={{
                    border: "1px solid black",
                    borderCollapse: "collapse",
                    padding: "6px",
                  }}
                >
                  Sr
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    borderCollapse: "collapse",
                    padding: "6px",
                  }}
                >
                  Item Code
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    borderCollapse: "collapse",
                    padding: "6px",
                  }}
                >
                  Description
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    borderCollapse: "collapse",
                    padding: "6px",
                  }}
                >
                  Quantity
                </th>
                {this.props.showUOM ? (
                  <th
                    style={{
                      border: "1px solid black",
                      borderCollapse: "collapse",
                      padding: "6px",
                    }}
                  >
                    UOM
                  </th>
                ) : null}
                <th
                  style={{
                    border: "1px solid black",
                    borderCollapse: "collapse",
                    padding: "6px",
                  }}
                >
                  Picked Qty (in Stock UOM){" "}
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    borderCollapse: "collapse",
                    padding: "6px",
                  }}
                >
                  Discount Amount
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    borderCollapse: "collapse",
                    padding: "6px",
                  }}
                >
                  Rate
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    borderCollapse: "collapse",
                    padding: "6px",
                  }}
                >
                  Amount
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    borderCollapse: "collapse",
                    padding: "6px",
                  }}
                >
                  Rate of Stock UOM
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    borderCollapse: "collapse",
                    padding: "6px",
                  }}
                >
                  Weight Per Unit
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    borderCollapse: "collapse",
                    padding: "6px",
                  }}
                >
                  Total Weight
                </th>
              </tr>
            </thead>
            <tbody
              style={{
                border: "1px solid black",
                borderCollapse: "collapse",
                fontSize: "10px",
              }}
            >
              {this.props.itemDetails.map((ele, index) => (
                <tr>
                  <td
                    style={{
                      border: "1px solid black",
                      borderCollapse: "collapse",
                      padding: "2px",
                    }}
                  >
                    {index + 1}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      borderCollapse: "collapse",
                      padding: "2px",
                    }}
                  >
                    {ele.item_code}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      borderCollapse: "collapse",
                      padding: "2px",
                    }}
                  >
                    {ele.description}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      borderCollapse: "collapse",
                      padding: "2px",
                    }}
                  >
                    {ele.qty}
                  </td>
                  {this.props.showUOM ? (
                    <td
                      style={{
                        border: "1px solid black",
                        borderCollapse: "collapse",
                        padding: "2px",
                      }}
                    >
                      {ele.uom}
                    </td>
                  ) : null}

                  <td
                    style={{
                      border: "1px solid black",
                      borderCollapse: "collapse",
                      padding: "2px",
                    }}
                  >
                    {ele.picked_qty}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      borderCollapse: "collapse",
                      padding: "2px",
                    }}
                  >
                    &#x20b9;
                    {ele.discount_amount
                      ? ele.discount_amount.toFixed(2)
                      : "0.00"}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      borderCollapse: "collapse",
                      padding: "2px",
                    }}
                  >
                    &#x20b9;{ele.rate ? ele.rate.toFixed(2) : "0.00"}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      borderCollapse: "collapse",
                      padding: "2px",
                    }}
                  >
                    &#x20b9;{ele.amount ? ele.amount.toFixed(2) : "0.00"}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      borderCollapse: "collapse",
                      padding: "2px",
                    }}
                  >
                    &#x20b9;
                    {ele.stock_uom_rate
                      ? ele.stock_uom_rate.toFixed(2)
                      : "0.00"}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      borderCollapse: "collapse",
                      padding: "2px",
                    }}
                  >
                    {ele.weight_per_unit}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      borderCollapse: "collapse",
                      padding: "2px",
                    }}
                  >
                    {ele.total_weight}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            // justifyContent: "space-around",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: "10px",
              width: "50%",
            }}
          >
            <table>
              <tr>
                <td align="left">
                  <b>Total Quantity:</b>
                </td>
                <td align="left">
                  {this.props.itemDetails.reduce(
                    (a, b) => a + Number(b.qty),
                    0
                  )}
                </td>
              </tr>
              <tr>
                <td align="left">
                  <b>Loyalty Points:</b>
                </td>
                <td align="left"></td>
              </tr>
            </table>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: "10px",
              width: "50%",
            }}
          >
            <table>
              <tr>
                <td align="left">
                  <b>Total:</b>
                </td>
                <td align="left">
                  {" "}
                  &#x20b9;
                  {this.props.customerDetails.total.toFixed(2)}
                </td>
              </tr>
              <tr>
                <td align="left"></td>
                <td align="left">
                  {this.props.customerDetails.loyalty_points}
                </td>
              </tr>
              <tr>
                <td align="left">
                  <b>Grand Total:</b>
                </td>
                <td align="left">
                  &#x20b9;{this.props.customerDetails.net_total.toFixed(2)}
                </td>
              </tr>
              <tr>
                <td align="left">
                  <b>Rounded Total:</b>
                </td>
                <td align="left">
                  &#x20b9;{this.props.customerDetails.rounded_total.toFixed(2)}
                </td>
              </tr>
              <tr>
                <td align="left">
                  <b>In Words:</b>
                </td>
                <td align="left">{this.props.customerDetails.in_words}</td>
              </tr>
              <tr>
                <td align="left">
                  <b>% Picked:</b>
                </td>
                <td align="left">
                  %{this.props.customerDetails.per_picked.toFixed(2)}
                </td>
              </tr>
              <tr>
                <td align="left">
                  <b>Amount Eligible for Commission:</b>
                </td>
                <td align="left">
                  &#x20b9;
                  {this.props.customerDetails.amount_eligible_for_commission.toFixed(
                    2
                  )}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: "10px",
            }}
          >
            <table>
              <tr>
                <td align="left">
                  <b>Citrix Order ID: </b>
                </td>
                <td align="left">
                  {this.props.customerDetails.citrix_order_id}
                </td>
              </tr>
              <tr>
                <td align="left">
                  <b>Citrix Company:</b>
                </td>
                <td align="left">
                  {this.props.customerDetails.citrix_company}
                </td>
              </tr>
              <tr>
                <td align="left">
                  <b>Address Verified:</b>
                </td>
                <td align="left">
                  {this.props.customerDetails.address_verified ? (
                    <CheckIcon />
                  ) : null}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
