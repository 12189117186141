import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import "./MUICard.style.css";

const MUICardHeader = ({ title, icon, action }) => (
  <CardHeader
    className="mui-card-header"
    title={
      <Typography
        style={{
          display: "flex",
          alignItems: "center",
        }}
        variant="h6"
        fontWeight={"bold"}
        textTransform={"capitalize"}
      >
        {icon}
        &nbsp;
        {title}
      </Typography>
    }
    action={action}
  />
);

const MainCard = ({
  children,
  padding,
  removeDefaultPadding,
  title,
  icon,
  className,
  removeBorder,
  style,
  action,
}) => {
  const paddingBottom = padding ? padding * 8 : removeDefaultPadding ? 0 : 16;

  return (
    <Card
      style={style}
      className={`${className}`}
      variant="outlined"
      sx={{ borderRadius: 2, border: removeBorder ? "none" : "" }}
    >
      {title ? (
        <MUICardHeader title={title} action={action} icon={icon} />
      ) : null}
      <CardContent sx={{ padding }} style={{ paddingBottom }}>
        {children}
      </CardContent>
    </Card>
  );
};

const SilentHeader = ({
  children,
  title,
  icon,
  action,
  padding,
  removeDefaultPadding,
}) => (
  <MainCard padding={0} removeDefaultPadding>
    <MainCard
      style={{
        borderRadius: 0,
      }}
      removeBorder
    >
      <Grid container>
        <Grid sm={8} item>
          <Typography
            variant="caption"
            textTransform={"uppercase"}
            fontWeight={"bold"}
          >
            {title}
          </Typography>
        </Grid>
        <Grid sm={4} style={{ textAlign: "right" }} item>
          {action}
        </Grid>
      </Grid>
    </MainCard>

    <MainCard
      style={{
        borderRadius: 0,
      }}
      removeBorder
      padding={padding}
      removeDefaultPadding={removeDefaultPadding}
    >
      {children}
    </MainCard>
  </MainCard>
);

const MUICard = ({
  children,
  padding,
  removeDefaultPadding,
  title,
  icon,
  className,
  removeBorder,
  style,
  action,
  silentHeader,
}) => {
  const mainCardProps = {
    children,
    padding,
    removeDefaultPadding,
    title,
    icon,
    className,
    removeBorder,
    style,
    action,
  };
  return silentHeader ? (
    <SilentHeader {...mainCardProps} />
  ) : (
    <MainCard {...mainCardProps} />
  );
};

export default MUICard;
