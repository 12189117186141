import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@mui/system";
import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextFieldComponent from "./textField";
import moment from 'moment'
const TextFieldContent = styled(TextField)`
  background-color: #f9fafa;
  & input {
    padding: 10px;
  }
`;
const Label = styled("div")`
  display: flex;
  flex-direction: row;
  color: #333c44;
  margin-bottom: 10px;
`;

const CustomerDetails = (props) => {
  const { order } = props;

  console.log('moment.utc(order.opened_utc) ', moment.utc(order.opened_utc).local().format('MM/DD/YYYY'), order.opened_utc)

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          textAlign: "left",
          width: "50%",
          margin: "10px",
        }}
      >
        <TextFieldComponent
          label="Apple Task ID"
          value={order.order_number}
          important={true}
        ></TextFieldComponent>
        <br />

        <TextFieldComponent
          label="Apple RITM ID"
          value={order.ritm_id}
          important={true}
        ></TextFieldComponent>
        <br />

        <TextFieldComponent
          label="Priority"
          value={order.priority}
        ></TextFieldComponent>
        <br />

        <TextFieldComponent
          label="Urgency"
          value={order.urgency}
        ></TextFieldComponent>
        <br />
        <br />

      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          textAlign: "left",
          width: "50%",
          margin: "10px",
        }}
      >
        <TextFieldComponent
          label="Customer"
          value={order.name}
          important={true}
        ></TextFieldComponent>
        <br />
        <TextFieldComponent
          label="Phone"
          value={order.phone}
        ></TextFieldComponent>
        <br />
        <TextFieldComponent
          label="Created Date"
          type="date"
          value={moment.utc(order.opened_utc).local().format('YYYY-MM-DD')}
          important={true}
        ></TextFieldComponent>
        <br></br>
        <TextFieldComponent
          label="Due Date"
          type="date"
          value={moment.utc(order.duedate_utc).local().format('YYYY-MM-DD')}
        ></TextFieldComponent>
        <br />
      </div>
    </>
  );
};

export default CustomerDetails;
