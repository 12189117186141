import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const CustomizedSnackbars = (props) => {
  const { severity, messageText, setStatusBase, status } = props;
  const [open, setOpen] = React.useState(true);
  const [message, setMessage] = React.useState("");

  const handleClose = (event, reason) => {
    setOpen(false);
    setMessage("");
    setStatusBase("");
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  React.useEffect(() => {
    if (message != "") {
      setOpen(true);
    }
  }, [message]);
  React.useEffect(() => {
    setMessage(messageText);
  }, [status]);
  React.useEffect(() => {
    return () => {
      setMessage("");
    };
  }, []);

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      {message && (
        <Snackbar
          id="alert"
          open={open}
          autoHideDuration={10000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      )}
    </Stack>
  );
};

export default CustomizedSnackbars;
