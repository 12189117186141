/**
 * Redux Actions to perform loading details like 
 * customers list, Apple's Task States, Yubikey Types List
 * City, State / Province List, Country
 */

import {
    GET_CUSTOMER_LIST,

    GET_CITY_LIST,
    GET_STATE_PROVINCE_LIST,
    GET_COUNTRY_LIST

} from "./actionTypes";
import invokeAPI from "../config/apiConfiguration";
import apiEndpoints from "../config/config";

/**
 * Redux Action to fetch all the customer names from our database
 * @returns 
 */
export const getCustomerList = () => (dispatch) => {
    invokeAPI(
        dispatch,
        `${apiEndpoints.CUSTOMER_LIST}`,
        "GET",
        null,
        GET_CUSTOMER_LIST,
        "customerList",
        [],
        null,
        null,
        null
    );
};


/**
 * Redux Action to fetch all the customer names from our database
 * @returns 
 */
export const getCityList = () => (dispatch) => {
    invokeAPI(
        dispatch,
        `${apiEndpoints.CITY_LIST}`,
        "GET",
        null,
        GET_CITY_LIST,
        "cityList",
        [],
        null,
        null,
        null
    );
};


/**
 * Redux Action to fetch all the customer names from our database
 * @returns 
 */
export const getStateProvinceList = () => (dispatch) => {
    invokeAPI(
        dispatch,
        `${apiEndpoints.STATE_PROVINCE_LIST}`,
        "GET",
        null,
        GET_STATE_PROVINCE_LIST,
        "stateProvinceList",
        [],
        null,
        null,
        null
    );
};


/**
 * Redux Action to fetch all the customer names from our database
 * @returns 
 */
export const getCountryList = () => (dispatch) => {
    invokeAPI(
        dispatch,
        `${apiEndpoints.COUNTRY_LIST}`,
        "GET",
        null,
        GET_COUNTRY_LIST,
        "countryList",
        [],
        null,
        null,
        null
    );
};