import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { pink } from '@mui/material/colors';

export default function CheckBoxComponent(props) {

    const [checked, setChecked] = React.useState(props.checked[props.field] || false)

    const handleChange = (event) => {
        setChecked(event.target.checked)

        props.handleChecked(props.id, {
            [props.field]: event.target.checked,
            order_number: props.order_number,
            order: props.order
        });
    };

    return (
        <Checkbox
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
            disabled={props.disabled}
            sx={{
                color: pink[600],
                '&.Mui-checked': {
                    color: 'success',
                },
            }}
        />
    );
}
