import {
  USER_ROLE,
  All_USERS,
  CURRENT_USER_ROLE,
} from "../actions/actionTypes";
import initialState from "./initialState";

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case USER_ROLE:
      return {
        ...state,
        userRole: action.payload,
      };
    case CURRENT_USER_ROLE:
      return {
        ...state,
        currentRole: action.payload,
      };
    case All_USERS: {
      return {
        ...state,
        allUsers: action.payload?.data?.payload?.allUsers,
      };
    }
    default:
      return state;
  }
}
