import React, { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const MUIDateField = (props) => {

    const { name, id, label, field, value, required, disabled, handleDateChange } = props


    const handleChange = (newValue) => {

        handleDateChange(id, {
            [field]: newValue,
            order_number: props.order_number,
            order: props.order
        })

    }
    return (<DatePicker
        name={name}
        id={id}
        label={label}
        value={value}
        required={required}
        disabled={disabled}
        onChange={handleChange}
    >
    </DatePicker>)
}

export default MUIDateField;