import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@mui/system";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TextField } from "@mui/material";
import { connect } from "react-redux";
import theme from "../../theme";
import TextFieldComponent from "./textField";
import TableHead from "@mui/material/TableHead";
import moment from 'moment'

const ItemsDetailsTab = styled("Paper")`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  margin: 20px;
`;
const ItemHeading = styled("p")`
  color: #6b7777;
  text-align: left;
`;
const ButtonContainer = styled("div")`
  display: flex;
  justify-content: flex-start;
  margin: 10px;
`;
const TextFieldContent = styled(TextField)`
  background-color: #f9fafa;
  & input {
    padding: 10px;
  }
`;
const Label = styled("div")`
  display: flex;
  flex-direction: row;
  color: #333c44;
  margin-bottom: 10px;
`;
const CustomerDetailsTab = styled("Paper")`
  display: flex;
  flex-direction: row;
  background-color: white;
  padding: 20px;
  margin: 20px;
  justify-content: flex-start;
`;
const ItemComponent = (props) => {
  const { order } = props;
  return (
    <div>
      <ItemsDetailsTab>
        <ItemHeading>Items</ItemHeading>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S.No</TableCell>
              <TableCell>Item Code</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Rate</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            <TableRow>
              <TableCell>{1}</TableCell>
              <TableCell>{order.yubikey_type}</TableCell>
              <TableCell>{(order.qty_needed === 0) ? 1 : order.qty_needed}</TableCell>
              <TableCell>{1}</TableCell>
              <TableCell>{1}</TableCell>
              <TableCell>
                <button disabled>Edit</button>
              </TableCell>
            </TableRow>

          </TableBody>
        </Table>
      </ItemsDetailsTab>
      <CustomerDetailsTab>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
            textAlign: "left",
            width: "50%",
            margin: "10px",
          }}
        >
          <TextFieldComponent
            disabled
            label="Total Quantity"
            value={(order.qty_needed === 0) ? 1 : order.qty_needed}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
            textAlign: "left",
            width: "50%",
            margin: "10px",
          }}
        >
          <TextFieldComponent
            label="Total Net Weight"
            value={0}
          />
          <br />
          <TextFieldComponent
            label="Total Amount"
            value={1}
          />
        </div>
      </CustomerDetailsTab>
    </div>
  );
};

export default ItemComponent;
