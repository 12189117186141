import {
  ORDERS_BY_STATUS,
  ORDERS_COUNT,
  GET_CUSTOMER_LIST,

  GET_CITY_LIST,
  GET_STATE_PROVINCE_LIST,
  GET_COUNTRY_LIST

} from "../actions/actionTypes";
import initialState from "./initialState";

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case ORDERS_BY_STATUS:
      return {
        ...state,
        selectedData: action.payload,
      };
    case ORDERS_COUNT:
      let formattedArray = [];
      action.payload.map(({ wflow_status, count }) =>
        formattedArray.push({
          [wflow_status]: count,
        })
      );
      return {
        ...state,
        orderCounts: Object.assign({}, ...formattedArray),
      };

    case GET_CUSTOMER_LIST:
      return {
        ...state,
        customerList: action.payload,
      };

    case GET_CITY_LIST:
      return {
        ...state,
        cityList: action.payload,
      };

    case GET_COUNTRY_LIST:
      return {
        ...state,
        countryList: action.payload,
      };

    case GET_STATE_PROVINCE_LIST:
      return {
        ...state,
        stateProvinceList: action.payload,
      };

    default:
      return state;
  }
}
