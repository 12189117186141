import { combineReducers } from "redux";
import authReducers from "./authReducers";
import userReducers from "./userReducers";
import orderReducers from "./orderReducers";
import loadFiltersReducer from "./loadFiltersReducer";
import snackbarReducer from "./snackbarReducer";

export default combineReducers({
  authReducers,
  userReducers,
  orderReducers,
  loadFiltersReducer,
  snackbarReducer,
});
