import { Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import MUICard from "../MUICard/MUICard.component";
import defaultProfilePicture from "../../assets/img/defaultProfilePicture.png";
import "./ConversationCard.style.css";
import moment from "moment";
import { LoadingButton } from "@mui/lab";

//MUI Icons:
import Edit from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";

import { useEffect, useState } from "react";

const ConversationCard = ({
  displayName,
  email,
  subtitle,
  message,
  handleEdit,
  callback,

  //Loading button props.
  isLoading,
}) => {
  //Application states:
  const [comment, setComment] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const toggleEdit = () => setIsEdit((e) => !e);

  //Application lifecycles:
  //Initial rendering: Assigning value before component render.
  useEffect(() => {
    setComment(message);
  }, [message]); //state will update when message will change.

  const profilePicture = defaultProfilePicture;
  const stringsArr = message.split("\n");
  const filterMessage = stringsArr.join(`<br />`);

  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const day = daysOfWeek[moment(subtitle).day()];
  const date = moment(subtitle).format("DD MMM");
  const time = moment(subtitle).format("hh:mm A");

  const filterSubtitle = `Added a public note on (${day}, ${date} ${time})`;

  const callbackResponse = {
    message: "",
    status: "",
    comment,
  };

  return (
    <MUICard>
      <Grid container>
        <Grid sm={12} item>
          <div className="profile-container">
            <MUICard
              padding={0}
              removeDefaultPadding
              removeBorder
              className="profile-picture"
            >
              <img src={profilePicture} width={"100%"} alt="user-profile" />
            </MUICard>
            <MUICard removeBorder style={{ width: "100%" }}>
              <Typography
                variant="body1"
                fontWeight={"bold"}
                textAlign={"left"}
              >
                {displayName}
                <Typography
                  component={"span"}
                  variant="body2"
                  color={"gray"}
                  textAlign={"left"}
                >
                  {email ? ` <${email}>` : ""}
                </Typography>
              </Typography>
              <Typography variant="body2" color={"gray"} textAlign={"left"}>
                {filterSubtitle}
              </Typography>
            </MUICard>
          </div>
        </Grid>
        {/* <Grid sm={2} item>
          <MUICard
            style={{ textAlign: "right" }}
            padding={1}
            removeDefaultPadding
            removeBorder
          >
            <IconButton
              style={{
                borderRadius: 6,
                border: "0.5px solid gray",
                margin: "0px 4px",
              }}
              onClick={toggleEdit}
            >
              {isEdit ? (
                <Close style={{ fontSize: "16px" }} />
              ) : (
                <Edit style={{ fontSize: "16px" }} />
              )}
            </IconButton>
          </MUICard>
        </Grid> */}
        <Grid sm={12} item>
          <div className="profile-container">
            <MUICard
              padding={0}
              removeDefaultPadding
              removeBorder
              className="profile-picture"
            ></MUICard>
            <MUICard
              padding={"0px 16px"}
              removeBorder
              style={{ width: "100%", textAlign: "left" }}
            >
              {isEdit ? (
                <Grid container spacing={2}>
                  <Grid sm={12} item>
                    <TextField
                      multiline
                      value={comment}
                      fullWidth
                      onChange={(e) => setComment(e.target.value)}
                    />
                  </Grid>
                  <Grid sm={10} item></Grid>
                  <Grid sm={2} item>
                    <LoadingButton
                      variant="contained"
                      color="success"
                      size="small"
                      fullWidth
                      onClick={() => callback(callbackResponse)}
                      loading={isLoading}
                    >
                      Update
                    </LoadingButton>
                  </Grid>
                </Grid>
              ) : (
                <Typography
                  style={{
                    display: "inline",
                  }}
                  variant="body1"
                  dangerouslySetInnerHTML={{ __html: filterMessage }}
                ></Typography>
              )}
            </MUICard>
          </div>
        </Grid>
      </Grid>
    </MUICard>
  );
};

export default ConversationCard;
