import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@mui/system";
import { connect } from "react-redux";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableHead from "@mui/material/TableHead";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import momentz from "moment-timezone"; // Make sure to have 'moment-timezone' installed.
import { visuallyHidden } from "@mui/utils";
import Box from "@mui/material/Box";
import ActionComponent from "./actionComponent";
import { stableSort, searchInputTable, getComparator } from "../utils";
import { centerAlignColumns, stickyColumn } from "../orders/constants";
import CheckBoxComponent from "./checkBoxComponent";
import TextBox from "./textBox";
import { Button, Grid, Icon, Paper } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import NotificationsIcon from "@mui/icons-material/Notifications";
import WarningIcon from "@mui/icons-material/Warning";
import Tooltip from "@mui/material/Tooltip";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//import { DatePicker } from '@material-ui/pickers';
import MUIDateField from "../common/dateComponent";

import { useNavigate } from "react-router-dom";

// Imports/conversations:
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";

//Imports/countries module:
import LanguageIcon from "@mui/icons-material/Language";

const TableCellContent = styled(TableCell)`
  whitespace: nowrap;
  padding: 10px 6px !important;
`;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    //padding: "10px 6px !important",
  },
  sticky: {
    position: "sticky",
    left: 0,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    console.log(event, "handleFirstPageButtonClick");
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    console.log(event, "handleBackButtonClick");
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    console.log(event, "handleNextButtonClick");
    // let offset = event.target.value * rowsPerPage;
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    console.log(event, "handleLastPageButtonClick");
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    classes,
    headCells,
    setIsBulkDelete,
    isBulkDelete,
    setSelectedOrders,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  console.log(headCells);

  return (
    <TableHead style={{ position: "sticky", top: 0, zIndex: 1 }}>
      <TableRow style={{}}>
        <TableCell
          align="center"
          style={{ color: "#174a84", fontWeight: "600" }}
        >
          Action
        </TableCell>

        {/* countries module */}
        {/* <TableCell
          align="center"
          style={{ color: "#174a84", fontWeight: "600" }}
        >
        </TableCell> */}

        {headCells.map((headCell) => (
          <>
            <TableCell
              key={headCell.id}
              align={
                centerAlignColumns.indexOf(headCell.id) > -1 ? "center" : "left"
              }
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              style={
                stickyColumn.indexOf(headCell.id) > -1
                  ? {
                    color: "#174a84",
                    fontWeight: "600",
                    position: "sticky",
                    left: 0,
                    background: "white",
                    borderRight: "2px solid black",
                    backgroundCcolor: "rgba(0, 0, 255,10)",
                    zIndex: "9000",
                  }
                  : { color: "#174a84", fontWeight: "600" }
              }
            >
              <TableSortLabel
                id="tableSort"
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                style={{ color: "#174a84" }}
              >
                <span>{headCell.showlabel}</span>

                {orderBy === headCell.id ? (
                  <Box
                    component="span"
                    sx={visuallyHidden}
                    style={{ color: "#174a84" }}
                  >
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            {/* {headCell.id === "additionalcommentsStr" ? (
              <TableCell
                align="center"
                style={{ color: "#174a84", fontWeight: "600" }}
              >
                Conversation
              </TableCell>
            ) : null} */}
          </>
        ))}
      </TableRow>
    </TableHead>
  );
}

const TableComponent = (props) => {
  const {
    order,
    orderBy,
    total,
    headCellData,
    data,
    handleRequestSort,
    actionComponent,
    setPage,
    setRowsPerPage,
    page,
    rowsPerPage,
    isBulkDelete,
    setIsBulkDelete,
    handleSelectedItemForDelete,
    setSelectedOrders,
    selectedOrders,
    customerList,

    updates,
    setUpdates,

    handleReload,

    // conversation modal action:
    viewConversations,

    // countries modal action:
    viewCountries,
  } = props;

  const navigate = useNavigate();

  //console.log(props, "props in table ");
  const handleChangePage = (event, newPage) => {
    console.log(newPage, "page==");
    // let offset = newPage * rowsPerPage;
    setPage(newPage);
    setSelectedOrders([]);
    setIsBulkDelete(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setSelectedOrders([]);
    setIsBulkDelete(false);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUpdates = (key, value) => {
    //console.log(' handleUpdates: key ', key, ' value ', value)

    if (value.hasOwnProperty("date_shipped")) {
      //let utcDateTime = moment.utc(value["date_shipped"])
      let utcDateTime = moment(value["date_shipped"])
        .startOf("day")
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");

      setUpdates((prevData) => {
        let temp = prevData.hasOwnProperty(key) ? prevData[key] : {};

        const newVal = { ...value, date_shipped: utcDateTime };

        let temp1 = { ...temp, ...newVal };

        return { ...prevData, [key]: temp1 };
      });
    } else {
      setUpdates((prevData) => {
        let temp = prevData.hasOwnProperty(key) ? prevData[key] : {};

        let temp1 = { ...temp, ...value };

        return { ...prevData, [key]: temp1 };
      });
    }
  };

  const minWidthColumn = (key) => {
    const widths = {
      address: 200,
      order_number: 120,
      address_2: 100,
      name: 110,
      title: 200,
      description: 200,
      if_number: 150,
      tracking_number: 200,
      international_tracking_number: 250,
      internal_memo: 250,
      additionalcommentsStr: 250,
      /* workNotesStr: 250, */
      serial_numbers: 250,
      date_shipped: 150,
    };
    return widths[key];
  };

  /**
   * Function to disable the fields if the order has invalid address or its completed
   * @param {String} key
   * @param {Object} row
   * @returns
   */
  const handleDisabled = (key, row) => {
    if (
      /* (row.invalid_address === true) || */
      (row.wflow_status === 8 && key !== "is_cancelled") ||
      (row.wflow_status !== 8 && key === "is_cancelled") ||
      row.invalid_address === true ||
      row.multipleIFS === true ||
      row.is_completed === true ||
      key === "serial_numbers" ||
      key === "date_shipped"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const convertToLocal = (date_shipped) => {
    console.log("date_shipped in convertToLocal ", date_shipped);

    //Step 1: First convert moment UTC date object to UTCDate
    const utcDate = moment.utc(date_shipped).toDate();
    // Step 2: Display the UTC time in the local time zone using the `.local()` method.
    const localTime = moment(utcDate).local().format("MM/DD/YYYY");
    return localTime;
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <Paper>
      <TableContainer
        sx={{
          width: "max-content",
          align: "left",
          overflow: "scroll",
          maxWidth: "auto",
          width: "100%",
        }}
      >
        <Table
          disableSticky="false"
          sx={{ minWidth: 650, position: "sticky", top: 100, zIndex: 1 }}
          aria-label="Orders table"
          stickyHeader
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={total}
            headCells={headCellData}
            isBulkDelete={isBulkDelete}
            setIsBulkDelete={setIsBulkDelete}
            setSelectedOrders={setSelectedOrders}
          />
          <TableBody>
            {stableSort(data, getComparator(order, orderBy)).map(
              (row, index) => {
                return (
                  <StyledTableRow key={`StyledTableRow${row["uuid"]}`}>
                    <StyledTableCell
                      key={`StyledTableRow${row["uuid"]}ActionCell`}
                    >
                      <div
                        style={{
                          width: "100px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ActionComponent
                          actions={actionComponent}
                          handleReload={handleReload}
                          row={row}
                        />
                        {/* </StyledTableCell> */}

                        {/* countries module: */}
                        {/* <StyledTableCell
                      key={`StyledTableRow${row["uuid"]}ActionCell`}
                    > */}
                        <Tooltip
                          title={"Netsuite Country Code"}
                          disableFocusListener
                          disableTouchListener
                          placement="top"
                          arrow
                        >
                          <IconButton
                            onClick={
                              () =>
                                viewCountries({
                                  order_number: row["order_number"],
                                  uuid: row["uuid"],
                                  order: row,
                                  country: row["country_1"],
                                })

                              // handleUpdates("country_1", )
                            }
                          >
                            <LanguageIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </StyledTableCell>

                    {Object.values({
                      ...headCellData.map((ele) => ele.id),
                    }).map((key) => {
                      return (
                        <>
                          <StyledTableCell
                            align={
                              centerAlignColumns.indexOf(key) > -1
                                ? "center"
                                : "left"
                            }
                            key={`StyledTableCell${key}`}
                            sx={{ minWidth: minWidthColumn(key) }}
                            style={
                              stickyColumn.indexOf(key) > -1
                                ? {
                                  color: "#174a84",
                                  fontWeight: "600",
                                  position: "sticky",
                                  left: 0,
                                  background: "white",
                                  borderRight: "2px solid black",
                                  backgroundCcolor: "rgba(0, 0, 255,1)",
                                  zIndex: "9000",
                                }
                                : {
                                  color: "#174a84",
                                  fontWeight: "600",
                                  background: "transparent",
                                }
                            }
                          >
                            {key === "req_loaded_in_db_utc" ? (
                              moment(row[key]).format("DD/MM/YYYY")
                            ) : key === "invalid_address" ||
                              key === "is_completed" ||
                              key === "delivered_to_local_entity" ||
                              key === "is_cancelled" ? (
                              <>
                                {key === "is_completed" &&
                                  row["multipleIFS"] === true ? (
                                  <Tooltip title="Multiple Item Fulfillments">
                                    <IconButton>
                                      <WarningIcon></WarningIcon>
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <></>
                                )}
                                <CheckBoxComponent
                                  key={`${row["uuid"]}_${key}`}
                                  checked={
                                    updates.hasOwnProperty(row["uuid"][key])
                                      ? updates[row["uuid"][key]]
                                      : { [key]: row[key] }
                                  }
                                  id={row["uuid"]}
                                  order_number={row["order_number"]}
                                  order={row}
                                  disabled={handleDisabled(key, row)}
                                  field={key}
                                  handleChecked={handleUpdates}
                                />
                              </>
                            ) : key === "serial_numbers" ||
                              key === "internal_memo" ||
                              key === "additionalcommentsStr" ||
                              /* key === 'workNotesStr' || */
                              key === "" ||
                              key === "tracking_number" ? (
                              <span>
                                <TextBox
                                  key={`${row["uuid"]}_${key}`}
                                  componentId={`${row["uuid"]}_${key}`}
                                  type={"text"}
                                  handleTextValue={handleUpdates}
                                  id={row["uuid"]}
                                  order_number={row["order_number"]}
                                  order={row}
                                  field={key}
                                  isMultiLine={
                                    key === "internal_memo" ||
                                      key ===
                                      "additionalcommentsStr" /* || (key === 'workNotesStr') */ ||
                                      key === "serial_numbers"
                                      ? true
                                      : ""
                                  }
                                  rows={
                                    key === "internal_memo" ||
                                      key ===
                                      "additionalcommentsStr" /* || (key === 'workNotesStr') */ ||
                                      key === "serial_numbers"
                                      ? 2
                                      : ""
                                  }
                                  value={
                                    updates.hasOwnProperty(row["uuid"][key])
                                      ? updates[row["uuid"][key]]
                                      : row[key]
                                        ? row[key]
                                        : ""
                                  }
                                  disabled={handleDisabled(key, row)}
                                />

                                {/* Author: Pratik Sonar <pratik.sonar@rahisystems.com> */}
                                {/* Add conversations view button beside comment box  */}
                                {/* Add a Boolean Flag to hide Conversation Module, Temporary Change */}
                                {false && key === "additionalcommentsStr" ? (
                                  <Tooltip
                                    title="View Conversations"
                                    disableFocusListener
                                    disableTouchListener
                                    placement="top"
                                    arrow
                                  >
                                    <IconButton
                                      onClick={() =>
                                        viewConversations(row["order_number"])
                                      }
                                      style={{ marginLeft: "2px" }}

                                    >
                                      <ForumOutlinedIcon
                                        color="primary"
                                        style={{ fontSize: "28px" }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                ) : null}

                                {/* endl */}
                              </span>
                            ) : key === "dateShippedUTC" ? (
                              row[key] != null &&
                                row[key] != undefined &&
                                row[key] !== "" ? (
                                <>{convertToLocal(row[key])}</>
                              ) : (
                                <></>
                              )
                            ) : /* <MUIDateField
                                    key={`${row['uuid']}_${key}`}
                                    id={row['uuid']}
                                    field={key}
                                    order_number={row['order_number']}
                                    order={row}
                                    required={false}
                                    disabled={handleDisabled(key, row)}
                                    handleDateChange={handleUpdates}
                                    value={moment(row['date_shipped'], 'MM/DD/YYYY') || moment()}
                                  /> */
                              key === "order_number" ? (
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    navigate(`/orderDetails/${row.uuid}`);
                                  }}
                                >
                                  {row[key]}
                                </span>
                              ) : (
                                <td
                                  style={{
                                    "white-space":
                                      key === "title" || key === "description"
                                        ? "pre-line"
                                        : "pre",
                                  }}
                                >
                                  {row[key]}
                                  {/*  */}
                                  {/* Adding Net suits country code to the next line of the full address */}
                                  {key === "full_address"
                                    ? `\nNS Country: (${row["country_1"]})`
                                    : null}
                                </td>
                              )}
                          </StyledTableCell>
                          {/* {key === "additionalcommentsStr" ? (
                            <StyledTableCell
                              align="center"
                              key={`StyledTableRow${row["uuid"]}ActionCell`}
                            >
                              <Button
                                onClick={() =>
                                  viewConversations(row["order_number"])
                                }
                              >
                                View
                              </Button>
                            </StyledTableCell>
                          ) : (
                            <></>
                          )} */}
                        </>
                      );
                    })}

                    {/* <StyledTableCell
                      align="center"
                      key={`StyledTableRow${row["uuid"]}ActionCell`}
                    >
                      <Button
                        onClick={() => viewConversations(row["order_number"])}
                      >
                        View
                      </Button>
                    </StyledTableCell> */}
                  </StyledTableRow>
                );
              }
            )}
          </TableBody>
          {/* <TableFooter
          style={{ width : "100%", maxWidth: 200, tableLayout: "fixed" }}
        >
          <TableRow
            style={{ width : "100%", maxWidth: 1000, }}
          >
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: total }]}
              //   colSpan={3}
              count={total ? total : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              style={{ width : "100%", maxWidth: 1000, }}
            />
          </TableRow>
        </TableFooter> */}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, { label: "All", value: total }]}
        //   colSpan={3}
        count={total ? total : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
        style={{
          float: "right",
          left: 0,
          bottom: 0,
          zIndex: 100,
          position: "sticky",
          background: "white",
          width: "100%",
        }}
      />
    </Paper >
  );
};

function mapStateToProps(state) {
  return {
    customerList: state.orderReducers.customerList || [],
  };
}
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TableComponent);
