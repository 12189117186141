import axios from "axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const refresh = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/refresh`,
      {
        withCredentials: true,
      }
    );
    setAuth((prev) => {
      return {
        ...prev,
        role: response.data.result.role,
        user_id: response.data.result.user_id,
        details: response.data.result,
        accessToken: response.data.accessToken,
      };
    });
    window.sessionStorage.setItem(
      "accessToken",
      `Bearer` + " " + response.data.accessToken
    );
    return response.data.accessToken;
  };
  return refresh;
};

export default useRefreshToken;
