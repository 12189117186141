import React from "react";
import { Autocomplete } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
// import CustomDateRangeInputs from "./datePicker";
import DatePickerRange from "./datePicker";
import theme from "../../theme";
import { addMonths } from "../utils";
import moment from "moment";
import {
  taskStatesFilterOptions,
  dateFilterOptions,
  regionsFilterOptions,
  yubikeyTypesFilterOptions,
} from "../orders/constants";

// Author: Pratik Sonar <pratik.sonar@rahisystems.com>
// Implement a function to validate filter props
const validateFileterProps = (props) => {
  const givenFilters = [
    "customerFilter",
    "taskStatesFilter",
    "yubikeyTypesFilter",
    "cityFilter",
    "stateProvinceFilter",
    "countryFilter",
  ];

  const validateFilters = [];

  givenFilters.forEach((filter) => {
    if (props[filter] && props[filter][0]) {
      validateFilters.push(false); // pushing 'false' if filters are available.
    }
  });

  return validateFilters.length ? false : true;
};
// endl

const FiltersComponent = ({ allProps, ...props }) => {
  const {
    filters,
    customerFilter,
    setCustomerFilter,
    taskStatesFilter,
    setTaskStatesFilter,
    dateFilter,
    setDateFilter,
    startDateFilter,
    setStartDateFilter,
    endDateFilter,
    setEndDateFilter,
    customerList,

    regionsFilter,
    setRegionsFilter,

    yubikeyTypesFilter,
    setYubikeyTypesFilter,

    cityFilter,
    setCityFilter,
    cityList,

    stateProvinceFilter,
    setStateProvinceFilter,
    stateProvinceList,

    countryFilter,
    setCountryFilter,
    countryList,

    //handleFilterBlur,
    isSubmitting,

    submitUpdates,
    updates,
  } = allProps;

  console.log(
    "#72 Debugging props validation: ",
    validateFileterProps(allProps),
    allProps
  );

  const handleCustomerFilter = (e, value) => {
    setCustomerFilter(value);
  };

  const handleCityFilter = (e, value) => {
    setCityFilter(value);
  };

  const handleStateProvinceFilter = (e, value) => {
    setStateProvinceFilter(value);
  };

  const handleCountryFilter = (e, value) => {
    setCountryFilter(value);
  };

  const handleTaskStatesFilter = (e, value) => {
    setTaskStatesFilter(value);
  };

  const handleRegionsFilter = (e, value) => {
    setRegionsFilter(value);
  };

  const handleYubikeyTypesFilter = (e, value) => {
    setYubikeyTypesFilter(value);
  };

  const handleDateFilter = (e, value) => {
    setDateFilter(value);
    if (value.name === "Past 6 months") {
      setStartDateFilter(addMonths(new Date(), -6));
      setEndDateFilter(moment(new Date()).format("YYYY-MM-DD"));
    }
    if (value.name === "Past 3 months") {
      setStartDateFilter(addMonths(new Date(), -3));
      setEndDateFilter(moment(new Date()).format("YYYY-MM-DD"));
    }
    if (value.name === "Custom") {
      setStartDateFilter("");
      setEndDateFilter("");
    }
  };

  /**
   * Clear the filters
   */
  const handleClear = () => {
    setCustomerFilter([]);
    setTaskStatesFilter([]);
    setDateFilter("");
    setStartDateFilter("");
    setEndDateFilter("");
    setYubikeyTypesFilter([]);
    setCityFilter([]);
    setStateProvinceFilter([]);
    setCountryFilter([]);
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {filters &&
        filters.map((ele) => {
          if (ele === "name") {
            return (
              <div style={{ margin: "10px" }}>
                <Autocomplete
                  multiple
                  limitTags={1}
                  disablePortal
                  size="small"
                  id="customer_dropdown"
                  value={customerFilter}
                  onChange={handleCustomerFilter}
                  //onBlur={handleFilterBlur}
                  options={customerList}
                  getOptionLabel={(option) => option.name ?? option}
                  renderOption={(props, option, { selected }) => (
                    console.log(selected, "selected"),
                    (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          // style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </li>
                    )
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Customers"
                      size="small"
                      style={{
                        fontSize: "12px",
                        backgroundColor: `${theme.textFieldColor}`,
                        borderRadius: "5px",
                      }}
                    />
                  )}
                  sx={{ width: "300px" }}
                />
              </div>
            );
          }
          if (ele === "task_states") {
            return (
              <div style={{ margin: "10px" }}>
                <Autocomplete
                  multiple
                  limitTags={1}
                  size="small"
                  id="task_states_dropdown"
                  value={taskStatesFilter}
                  onChange={handleTaskStatesFilter}
                  options={taskStatesFilterOptions}
                  //onBlur={handleFilterBlur}
                  getOptionLabel={(option) => option.name ?? option}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        // style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Apple's Task Status"
                      size="small"
                      style={{
                        fontSize: "12px",
                        backgroundColor: `${theme.textFieldColor}`,
                        borderRadius: "5px",
                      }}
                    />
                  )}
                  sx={{ width: "200px" }}
                />
              </div>
            );
          }

          if (ele === "city") {
            return (
              <div style={{ margin: "10px" }}>
                <Autocomplete
                  multiple
                  limitTags={1}
                  size="small"
                  id="cities_dropdown"
                  value={cityFilter}
                  onChange={handleCityFilter}
                  options={cityList}
                  //onBlur={handleFilterBlur}
                  getOptionLabel={(option) => option.name ?? option}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="City"
                      size="small"
                      style={{
                        fontSize: "12px",
                        backgroundColor: `${theme.textFieldColor}`,
                        borderRadius: "5px",
                      }}
                    />
                  )}
                  sx={{ width: "150px" }}
                />
              </div>
            );
          }

          if (ele === "state_province") {
            return (
              <div style={{ margin: "10px" }}>
                <Autocomplete
                  multiple
                  limitTags={1}
                  size="small"
                  id="state_province_dropdown"
                  value={stateProvinceFilter}
                  onChange={handleStateProvinceFilter}
                  options={stateProvinceList}
                  //onBlur={handleFilterBlur}
                  getOptionLabel={(option) => option.name ?? option}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State / Province"
                      size="small"
                      style={{
                        fontSize: "12px",
                        backgroundColor: `${theme.textFieldColor}`,
                        borderRadius: "5px",
                      }}
                    />
                  )}
                  sx={{ width: "200px" }}
                />
              </div>
            );
          }

          if (ele === "country_1") {
            return (
              <div style={{ margin: "10px" }}>
                <Autocomplete
                  multiple
                  limitTags={1}
                  size="small"
                  id="country_dropdown"
                  value={countryFilter}
                  onChange={handleCountryFilter}
                  options={countryList}
                  //onBlur={handleFilterBlur}
                  getOptionLabel={(option) => option.name ?? option}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country"
                      size="small"
                      style={{
                        fontSize: "12px",
                        backgroundColor: `${theme.textFieldColor}`,
                        borderRadius: "5px",
                      }}
                    />
                  )}
                  sx={{ width: "150px" }}
                />
              </div>
            );
          }
          /* 
                    if (ele === "regions") {
                      return (
                        <div style={{ margin: "10px" }}>
                          <Autocomplete
                            multiple
                            limitTags={1}
                            size="small"
                            id="regions_dropdown"
                            value={regionsFilter}
                            onChange={handleRegionsFilter}
                            options={regionsFilterOptions}
                            //onBlur={handleFilterBlur}
                            getOptionLabel={(option) => option.name ?? option}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  checked={selected}
                                />
                                {option.name}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Regions"
                                size="small"
                                style={{
                                  fontSize: "12px",
                                  backgroundColor: `${theme.textFieldColor}`,
                                  borderRadius: "5px",
                                }}
                              />
                            )}
                            sx={{ width: "150px" }}
                          />
                        </div>
                      );
                    } */

          if (ele === "yubikey_types") {
            return (
              <div style={{ margin: "10px" }}>
                <Autocomplete
                  multiple
                  limitTags={1}
                  size="small"
                  id="yubikey_types_dropdown"
                  value={yubikeyTypesFilter}
                  onChange={handleYubikeyTypesFilter}
                  options={yubikeyTypesFilterOptions}
                  //onBlur={handleFilterBlur}
                  getOptionLabel={(option) => option.name ?? option}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Yubikey Types"
                      size="small"
                      style={{
                        fontSize: "12px",
                        backgroundColor: `${theme.textFieldColor}`,
                        borderRadius: "5px",
                      }}
                    />
                  )}
                  sx={{ width: "150px" }}
                />
              </div>
            );
          }

          if (ele === "created_date") {
            return (
              <div>
                {dateFilter && dateFilter.name === "Custom" ? (
                  <div
                    style={{
                      margin: "10px",
                      borderRadius: "4px",
                      border: "1px solid #b9b2b2",
                      padding: "7px 10px",
                      display: "flex",
                      backgroundColor: `${theme.textFieldColor}`,
                    }}
                  >
                    <DatePickerRange
                      startDateFilter={startDateFilter}
                      setStartDateFilter={setStartDateFilter}
                      endDateFilter={endDateFilter}
                      setEndDateFilter={setEndDateFilter}
                    />
                    <div
                      style={{
                        margin: "0px 10px",
                        color: "rgb(160 154 154)",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setDateFilter("");
                        setStartDateFilter("");
                        setEndDateFilter("");
                      }}
                    >
                      X
                    </div>
                  </div>
                ) : (
                  <div style={{ margin: "10px" }}>
                    <Autocomplete
                      id="date_dropdown"
                      size="small"
                      value={dateFilter}
                      onChange={handleDateFilter}
                      options={dateFilterOptions}
                      getOptionLabel={(option) => option.name ?? option}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Requested Date"
                          size="small"
                          style={{
                            fontSize: "12px",
                            backgroundColor: `${theme.textFieldColor}`,
                            borderRadius: "5px",
                          }}
                          //   placeholder="Favorites"
                        />
                      )}
                      sx={{ width: "300px" }}
                    />
                  </div>
                )}
              </div>
            );
          }
        })}
      <Button
        // style={{
        //   backgroundColor: "rgb(224 219 219 / 42%)",
        //   color: "black",
        //   textTransform: "capitalize",
        // }}

        onClick={handleClear}
        //
        // Author: Pratik Sonar <pratik.sonar@rahisystems.com>
        // Enhance Clear Button styling.
        sx={{
          backgroundColor: "rgba(224, 219, 219, 1)",
          "&:hover": { backgroundColor: "rgba(224, 219, 219, 0.6)" },
          color: "#000",
          textTransform: "capitalize",
        }}
        // Implement a functionality to disable a Button if filters are empty.
        disabled={validateFileterProps(allProps)}
        //
      >
        Clear
      </Button>

      <div
        style={{
          margin: "10px",
          float: "right",
          position: "absolute",
          right: 30,
        }}
      >
        <Button
          color="primary"
          variant="contained"
          disabled={!Object.keys(updates).length || isSubmitting}
          onClick={submitUpdates}
        >
          Submit Changes
        </Button>
      </div>
    </div>
  );
};

export default FiltersComponent;
