import restrictedCountryCodes from "../config/restrictedCountries";

/**
 * A functionality to check if the country is restricted?
 *
 *
 *
 *
 * @param {string} isoCode Country ISO Code
 * @returns {boolean}
 */
const isCountryRestricted = (isoCode) => {
  const restricedISOs = Object.values(restrictedCountryCodes);
  if (restricedISOs.includes(isoCode)) return true;
  else return false;
};

export default isCountryRestricted;
