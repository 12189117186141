import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@mui/system";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableHead from "@mui/material/TableHead";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { visuallyHidden } from "@mui/utils";
import Box from "@mui/material/Box";
import { stableSort, searchInputTable, getComparator } from "../utils";
import { headCells, tableActions, filters, wflow_status } from "./constants";
import TableComponent from "../common/table";
import FiltersComponent from "../common/filters";
import useAuth from "../../hooks/useAuth";
import { USER_LOGOUT_URL } from "../../config/config";
import axios from "axios";
import BallotIcon from "@mui/icons-material/Ballot";
import CancelIcon from "@mui/icons-material/Cancel";
import WarningIcon from "@mui/icons-material/WarningRounded";
import { toast } from "react-toastify";
import { successToastOptions, errorToastOptions } from "../orders/constants";

//Imports/conversations module:
import ForumIcon from "@mui/icons-material/Forum";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";

// Imports/countries module:

import {
  getOrderListByStatus,
  getOrdersCount,
  updateOrders,
  addComment,
  getComments,
} from "../../actions/orderAction";

import {
  getCustomerList,
  getCityList,
  getStateProvinceList,
  getCountryList,
} from "../../actions/loadFiltersAction";
import MUICard from "../MUICard/MUICard.component";
import ConversationCard from "../ConversationCard/ConversationCard.component";
// import comments from "../../samples/comments.sample";
import moment from "moment";
import { LoadingButton } from "@mui/lab";

const HeadingContainer = styled("div")`
  text-align: left;
  padding
`;
const Heading = styled("h1")``;
const Root = styled("div")`
  padding: 40px;
  // width: 1024px;
  margin: auto;
`;
const TabsContainer = styled("div")`
  padding: 20px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const TableCellContent = styled(TableCell)`
  whitespace: nowrap;
  padding: 10px 6px !important;
`;
const TextFieldSearch = styled(TextField)`
  whitespace: nowrap;
  padding: 10px 6px !important;
  & input {
    padding: 10px;
  }
`;
const SearchContent = styled("div")`
  display: flex;
  // justify-content: flex-end;
  width: 100%;
  align-items: center;
`;
const Tabs = styled("div", {
  name: "Tabs",
  slot: "Wrapper",
})((props) => ({
  width: "175px",
  height: "80px",
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  fontWeight: 500,
  backgroundColor: "rgba(17, 20, 18, 0.6)",
  borderRadius: "11px",
  margin: "10px 0px",
  cursor: "pointer",
  fontSize: "13.5px",
  fontWeight: "bold",
}));

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    classes,
    headCells,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow style={{}}>
        <TableCell
          align="center"
          style={{ color: "#174a84", fontWeight: "600" }}
        >
          Action
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            // className={classes.tableHeadcell}
            style={{ color: "#174a84", fontWeight: "600" }}
          >
            <TableSortLabel
              id="tableSort"
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              style={{ color: "#174a84" }}
            >
              <span>{headCell.showlabel}</span>

              {orderBy === headCell.id ? (
                <Box
                  component="span"
                  sx={visuallyHidden}
                  style={{ color: "#174a84" }}
                >
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
function OrderList(props) {
  const {
    getOrderListByStatus,
    selectedData,
    getOrdersCount,
    orderCounts,
    getCustomerList,
    customerList,

    cityList,
    getCityList,

    stateProvinceList,
    getStateProvinceList,

    countryList,
    getCountryList,
    updateOrders,

    //add comments:
    addComment,
    getComments,
  } = props;
  console.log("orderCounts ", orderCounts);

  // Conversation Modal: <pratik.sonar@rahisystems.com>
  const [openConversationModal, setOpenConversationModal] = useState(false);
  const [taskNumber, setTaskNumber] = useState("");
  const [commentText, setCommentText] = useState("");
  const [isAddComment, setIsAddComment] = useState(false);
  const [comments, setComments] = useState([]);
  const [fetchingComments, setFetchingComments] = useState(false);

  //Conversation modal Toggle function:
  const toggleConversationModal = () => setOpenConversationModal((e) => !e);

  //end mdls

  // Countries Modal: <pratik.sonar@rahisystems.com>
  const [openCountriesModal, setOpenCountriesModal] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [updateCountryBuffer, setUpdateCountryBuffer] = useState({}); // For holding neccessary data to update country.
  const [isCountryUpdating, setIsCountryUpdating] = useState(false);

  //Countries modal Toggle function:
  const toggleCountriesModal = () => setOpenCountriesModal((e) => !e);

  //end mdls

  //<pratik.sonar@rahisystems.com>
  //Implementing Elastic searching:

  //states:
  const [searchType, setSearchType] = useState("");
  const [searchString, setSearchString] = useState("");

  //

  const [selected, setSelected] = useState(9);
  // const [selectedData, setSelectedData] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [searchInputText, setInputText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [orderBy, setOrderBy] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [dateFilter, setDateFilter] = useState(null);
  const [customerFilter, setCustomerFilter] = useState([]);
  const [taskStatesFilter, setTaskStatesFilter] = useState([]);

  const [cityFilter, setCityFilter] = useState([]);
  const [stateProvinceFilter, setStateProvinceFilter] = useState([]);
  const [countryFilter, setCountryFilter] = useState([]);

  //const [regionsFilter, setRegionsFilter] = useState([]);
  const [yubikeyTypesFilter, setYubikeyTypesFilter] = useState([]);

  const [startDateFilter, setStartDateFilter] = useState("");
  const [endDateFilter, setEndDateFilter] = useState("");
  const [isBulkDelete, setIsBulkDelete] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [filter, setFilter] = useState({
    /*  "yubikey_types": [],
     "city": [],
     "country_1": [],
     "customer": [],
     "state_province": [],
     "task_states": [], */
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const { setAuth } = useAuth();
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [updates, setUpdates] = useState({});

  useEffect(() => {
    console.log(" updates ", JSON.stringify(updates));
  }, [updates]);

  const handleSearch = (searchText) => {
    // let formattedArray = selectedData.map((ele) => ({
    //   ...ele,
    //   customerName: getCustomerName(ele.customer),
    // }));

    // Implemented for rapid response.
    const findText = searchText !== null ? searchText : searchInputText; //Implemented by pratik.sonar@rahisystems.com

    // let arrayOfMatchedObjects = searchInputTable(formattedArray, findText);

    // setSearchResults([...arrayOfMatchedObjects]);

    //
    // <pratik.sonar@rahisystems.com>
    setSearchType("ELASTIC"); // default
    setSearchString(findText); // Contains a string
    //
  };

  const getCustomerName = (id) => {
    if (customerList.length) {
      const customerObj = customerList.find(
        (ele) => Number(ele.id) === Number(id)
      );
      if (customerObj) {
        return customerObj.name;
      }
    }
  };

  const handleSelectedItemForDelete = (e, row) => {
    let res = [...selectedOrders];
    if (e.target.checked) {
      res.push(row);
    } else if (!e.target.checked) {
      res = res.filter((ele) => ele.citrix_Order_ID !== row.citrix_Order_ID);
    }
    setSelectedOrders([...res]);
    console.log(res, "deleetd");
  };

  const handleLogout = async () => {
    setAuth({});
    try {
      const response = await axios.get(USER_LOGOUT_URL, {
        withCredentials: true,
      });
      window && window.sessionStorage.removeItem("accessToken");
      window && window.sessionStorage.removeItem("user_id");
      window && window.localStorage.removeItem("persist");
    } catch (err) {
      console.error(err);
    }
    navigate("/login");
  };

  useEffect(() => {
    console.log(
      selected,
      filter,
      startDateFilter,
      endDateFilter,
      rowsPerPage,
      page
    );
    if (selected && JSON.stringify(filter) !== "{}") {
      getOrderListByStatus(
        selected,
        filter.task_states,

        null, //filter.regions
        filter.yubikey_types,

        filter.customer,

        filter.city,
        filter.state_province,
        filter.country_1,

        startDateFilter,
        endDateFilter,
        rowsPerPage,
        page ? page * 5 : page,

        //
        //
        searchType,
        searchString
      );

      // Author: Pratik Sonar <pratik.sonar@rahisystems.com>
      // Calling a function to get orders count.
      getOrdersCount({...filter, searchType, searchString});
      // 
    }
  }, [
    selected,
    filter,
    startDateFilter,
    endDateFilter,
    rowsPerPage,
    page,
    searchString,
  ]);

  useEffect(() => {
    getOrdersCount();
  }, []);

  useEffect(() => {
    if (customerList.length < 1) {
      getCustomerList();
    }
  }, [customerList.length]);

  useEffect(() => {
    if (cityList.length < 1) {
      getCityList();
    }
  }, [cityList.length]);

  useEffect(() => {
    if (stateProvinceList.length < 1) {
      getStateProvinceList();
    }
  }, [stateProvinceList.length]);

  useEffect(() => {
    if (countryList.length < 1) {
      getCountryList();
    }
  }, [countryList.length]);

  useEffect(() => {
    //console.log(selectedData, "data--");
    if (selectedData.length) {
      setSearchResults([...selectedData.map((ele) => ele)]);
    } else {
      setSearchResults([]);
    }
  }, [selectedData]);

  /**
   * Fetch the orders list whenever filters change
   */
  useEffect(() => {
    let filterObj = {};

    if (taskStatesFilter) {
      filterObj["task_states"] = taskStatesFilter.map((ele) => ele.id);
    }
    /* if (regionsFilter) {
      filterObj["regions"] = regionsFilter.map((ele) => ele.id);
    } */

    if (yubikeyTypesFilter) {
      filterObj["yubikey_types"] = yubikeyTypesFilter.map((ele) => ele.id);
    }

    if (customerFilter) {
      filterObj["customer"] = customerFilter.map((ele) => ele.id);
    }

    if (cityFilter) {
      filterObj["city"] = cityFilter.map((ele) => ele.id);
    }

    if (stateProvinceFilter) {
      filterObj["state_province"] = stateProvinceFilter.map((ele) => ele.id);
    }

    if (countryFilter) {
      // filterObj["country_1"] = countryFilter.map((ele) => ele.id); //Akhil B
      filterObj["country_1"] = countryFilter.map((ele) => ele.code); //Pratik S
    }
    setFilter(filterObj);
  }, [
    customerFilter,
    yubikeyTypesFilter,
    /* regionsFilter, */ cityFilter,
    stateProvinceFilter,
    countryFilter,
  ]);

  const reload = () => {
    console.log("reload of function is called ");
    if (selected) {
      getOrderListByStatus(
        selected,
        filter.task_states,

        null, //filter.regions,
        filter.yubikey_types,

        filter.customer,

        filter.city,
        filter.state_province,
        filter.country_1,

        startDateFilter,
        endDateFilter,
        rowsPerPage,
        page ? page * 5 : page
      );

      getOrdersCount();
    }
  };
  const submitUpdates = () => {
    setIsSubmitting((prevData) => true);

    const loading = toast.loading("Updating in progress");

    console.log("submitUpdates: loading ", loading);

    updateOrders({ updates }, ({ error, status, message }) => {
      setIsSubmitting(false);

      console.log("error in submitUpdates ", error);

      if (error) return toast.update(loading, errorToastOptions(error));
      else {
        toast.update(loading, successToastOptions(message));

        setUpdates((prev) => "");
        reload();
      }
    });
  };

  // -----------Conversation-modal-handling-functionalities--------
  // -----------------<pratik.sonar@rahisystems.com>---------------

  const handleViewConversations = (identifier) => {
    setComments([]); //cleaning comments state before adding new comments.
    setFetchingComments(true);
    //setting task number:
    setTaskNumber(identifier);
    getComments({ taskId: identifier }, (res) => {
      setComments(res.result);
      setFetchingComments(false);
    });

    //toggle conversation modal:
    toggleConversationModal();
  };

  //Add comment:
  const handleSubmitComment = async () => {
    setIsAddComment(true);
    const loading = toast.loading("Sending Comment...");

    const commentBody = {
      number: taskNumber,
      additionalComments: commentText,
      workLog: "",
      state: "Work in Progress",
    };

    console.log("User Comment: ", commentBody);

    addComment(commentBody, ({ error, status, message }) => {
      if (status === "SUCCESS") {
        message = "Sent Successfully";
        toast.update(loading, successToastOptions(message));
        setCommentText("");
        setIsAddComment(false);
      } else {
        toast.update(loading, errorToastOptions(error));
      }
    });
  };

  // -----------Conversation-modal-handling-functionalities--------
  // -----------------<pratik.sonar@rahisystems.com>---------------

  const handleViewCountries = (resp) => {
    const country = resp?.country ? resp.country : "";
    setSelectedCountry({ uuid: resp.uuid, country }); //

    setUpdateCountryBuffer((prev) => ({
      ...prev,
      [resp.uuid]: {
        order: resp.order,
        order_number: resp.order_number,
        country_1: country,
      },
    }));

    toggleCountriesModal(); //
  };

  //Implementing 'useEffects' to handle change behaviour of country.
  useEffect(() => {
    if (selectedCountry.country) {
      setUpdateCountryBuffer((prev) => {
        const isOrderPresent = prev.hasOwnProperty(selectedCountry.uuid)
          ? prev[selectedCountry.uuid]
          : {};

        console.log("Present Order: ", isOrderPresent);
        const mergeLatestUpdate = {
          ...isOrderPresent,
          country_1: selectedCountry.country,
        };

        return { ...prev, [selectedCountry.uuid]: mergeLatestUpdate };
      });
    }
  }, [selectedCountry]);

  //Implementing a function to submit update country request to the backend.
  const handleUpdateCountry = async () => {
    setIsCountryUpdating(true); // started execution
    const loading = toast.loading("Updating in progress");

    updateOrders(
      { updates: updateCountryBuffer },
      ({ error, status, message }) => {
        setIsCountryUpdating(false);

        if (error) return toast.update(loading, errorToastOptions(error));
        else {
          toast.update(loading, successToastOptions(message));

          setUpdateCountryBuffer({}); // Resetting buffer.
          toggleCountriesModal();
          reload(); // Refreshing for latest change.
        }
      }
    );
  };

  // -----------end------------------------------------------------

  //console.log(taskStatesFilter, regionsFilter, yubikeyTypesFilter, customerFilter, "yui");

  return (
    <Root>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button variant="contained" onClick={handleLogout}>
          Logout
        </Button>
      </div>

      <HeadingContainer>
        <Heading>Shipment Dashboard</Heading>
      </HeadingContainer>
      <TabsContainer>
        <Tabs
          onClick={() => {
            setSelected(wflow_status["all_active"]);
            setPage(0);
            setRowsPerPage(5);
          }}
          style={{
            backgroundColor:
              selected === wflow_status["all_active"]
                ? "rgb(42, 130, 253)"
                : "#FFF",
            color:
              selected === wflow_status["all_active"]
                ? "white"
                : "rgba(17, 20, 18, 0.6)",
            border:
              selected === wflow_status["all_active"]
                ? "1px solid rgb(42, 130, 253)"
                : "1px solid rgba(17, 20, 18, 0.6) ",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100px",
              justifyContent: "space-evenly",
            }}
          >
            <div>
              <BallotIcon fontSize="large" />
            </div>
            <h3>
              {orderCounts[wflow_status["all_active"]]
                ? orderCounts[wflow_status["all_active"]]
                : 0}
            </h3>
          </div>

          <div>All Active Orders</div>
        </Tabs>
        <Tabs
          onClick={() => {
            setSelected(wflow_status["Pending Sales Order"]);
            setPage(0);
            setRowsPerPage(5);
          }}
          style={{
            backgroundColor:
              selected === wflow_status["Pending Sales Order"]
                ? "rgb(42, 130, 253)"
                : "#FFF",
            color:
              selected === wflow_status["Pending Sales Order"]
                ? "white"
                : "rgba(17, 20, 18, 0.6)",
            border:
              selected === wflow_status["Pending Sales Order"]
                ? "1px solid rgb(42, 130, 253)"
                : "1px solid rgba(17, 20, 18, 0.6) ",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100px",
              justifyContent: "space-evenly",
            }}
          >
            <div>
              <svg
                width="42"
                height="38"
                viewBox="0 0 42 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.9498 3.9845C12.1572 3.9845 11.3971 4.29935 10.8367 4.85978C10.2762 5.42022 9.96136 6.18034 9.96136 6.97291V11.9536H7.96909V13.9459H9.96136V23.9073H7.96909V25.8996H9.96136V30.8803C9.96136 31.6728 10.2762 32.4329 10.8367 32.9934C11.3971 33.5538 12.1572 33.8687 12.9498 33.8687H30.8803C31.6729 33.8687 32.433 33.5538 32.9934 32.9934C33.5539 32.4329 33.8687 31.6728 33.8687 30.8803V6.97291C33.8687 6.18034 33.5539 5.42022 32.9934 4.85978C32.433 4.29935 31.6729 3.9845 30.8803 3.9845H12.9498ZM17.9305 13.9459C17.9305 12.8891 18.3503 11.8756 19.0975 11.1284C19.8448 10.3811 20.8583 9.96133 21.915 9.96133C22.9718 9.96133 23.9853 10.3811 24.7325 11.1284C25.4798 11.8756 25.8996 12.8891 25.8996 13.9459C25.8996 15.0027 25.4798 16.0161 24.7325 16.7634C23.9853 17.5106 22.9718 17.9304 21.915 17.9304C20.8583 17.9304 19.8448 17.5106 19.0975 16.7634C18.3503 16.0161 17.9305 15.0027 17.9305 13.9459ZM15.9382 25.8996C15.9382 24.3144 16.5679 22.7942 17.6888 21.6733C18.8096 20.5524 20.3299 19.9227 21.915 19.9227C23.5002 19.9227 25.0204 20.5524 26.1413 21.6733C27.2622 22.7942 27.8919 24.3144 27.8919 25.8996V27.8918H15.9382V25.8996Z"
                  fill={
                    selected === wflow_status["Pending Sales Order"]
                      ? "white"
                      : "rgba(17, 20, 18, 0.6)"
                  }
                />
              </svg>
            </div>
            <h3>
              {orderCounts[wflow_status["Pending Sales Order"]]
                ? orderCounts[wflow_status["Pending Sales Order"]]
                : 0}
            </h3>
          </div>

          <div>Pending Sales Order</div>
        </Tabs>
        <Tabs
          onClick={() => {
            setSelected(wflow_status["Pending Completion"]);
            setPage(0);
            setRowsPerPage(5);
          }}
          style={{
            backgroundColor:
              selected === wflow_status["Pending Completion"]
                ? "rgb(42, 130, 253)"
                : "#FFF",
            color:
              selected === wflow_status["Pending Completion"]
                ? "white"
                : "rgba(17, 20, 18, 0.6)",
            border:
              selected === wflow_status["Pending Completion"]
                ? "1px solid rgb(42, 130, 253)"
                : "1px solid rgba(17, 20, 18, 0.6) ",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100px",
              justifyContent: "space-evenly",
            }}
          >
            <svg
              width="38"
              height="38"
              viewBox="0 0 38 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.7612 27.9956H24.092C24.4338 27.9956 24.7163 27.8839 24.9398 27.6605C25.1632 27.437 25.2749 27.1545 25.2749 26.8127C25.2749 26.471 25.1632 26.1884 24.9398 25.965C24.7163 25.7415 24.4338 25.6298 24.092 25.6298H13.7612C13.4195 25.6298 13.1369 25.7415 12.9135 25.965C12.69 26.1884 12.5783 26.471 12.5783 26.8127C12.5783 27.1545 12.69 27.437 12.9135 27.6605C13.1369 27.8839 13.4195 27.9956 13.7612 27.9956V27.9956ZM13.7612 21.2925H24.092C24.4338 21.2925 24.7163 21.1807 24.9398 20.9573C25.1632 20.7339 25.2749 20.4513 25.2749 20.1095C25.2749 19.7678 25.1632 19.4852 24.9398 19.2618C24.7163 19.0383 24.4338 18.9266 24.092 18.9266H13.7612C13.4195 18.9266 13.1369 19.0383 12.9135 19.2618C12.69 19.4852 12.5783 19.7678 12.5783 20.1095C12.5783 20.4513 12.69 20.7339 12.9135 20.9573C13.1369 21.1807 13.4195 21.2925 13.7612 21.2925ZM8.6747 34.6988C8.04381 34.6988 7.49178 34.4622 7.01862 33.9891C6.54545 33.5159 6.30887 32.9639 6.30887 32.333V5.52025C6.30887 4.88936 6.54545 4.33733 7.01862 3.86417C7.49178 3.391 8.04381 3.15442 8.6747 3.15442H21.9233C22.2388 3.15442 22.5477 3.22014 22.85 3.35157C23.1523 3.48301 23.4086 3.65387 23.6189 3.86417L30.8346 11.08C31.0449 11.2902 31.2158 11.5465 31.3472 11.8488C31.4787 12.1511 31.5444 12.46 31.5444 12.7755V32.333C31.5444 32.9639 31.3078 33.5159 30.8346 33.9891C30.3615 34.4622 29.8095 34.6988 29.1786 34.6988H8.6747ZM21.7262 11.6714C21.7262 12.0131 21.8379 12.2957 22.0614 12.5192C22.2848 12.7426 22.5674 12.8543 22.9091 12.8543H29.1786L21.7262 5.52025V11.6714Z"
                fill={
                  selected === wflow_status["Pending Completion"]
                    ? "#fff"
                    : "rgba(17, 20, 18, 0.6)"
                }
              />
            </svg>
            <h3>
              {" "}
              {orderCounts[wflow_status["Pending Completion"]]
                ? orderCounts[wflow_status["Pending Completion"]]
                : 0}
            </h3>
          </div>
          <div>Pending Completion</div>
        </Tabs>
        <Tabs
          onClick={() => {
            setSelected(wflow_status["Completed"]);
            setPage(0);
            setRowsPerPage(5);
          }}
          style={{
            backgroundColor:
              selected === wflow_status["Completed"]
                ? "rgb(42, 130, 253)"
                : "#FFF",
            color:
              selected === wflow_status["Completed"]
                ? "white"
                : "rgba(17, 20, 18, 0.6)",
            border:
              selected === wflow_status["Completed"]
                ? "1px solid rgb(42, 130, 253)"
                : "1px solid rgba(17, 20, 18, 0.6) ",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100px",
              justifyContent: "space-evenly",
            }}
          >
            <svg
              width="38"
              height="38"
              viewBox="0 0 38 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.67473 34.6988C8.04384 34.6988 7.49181 34.4622 7.01865 33.9891C6.54548 33.5159 6.3089 32.9639 6.3089 32.333V5.52025C6.3089 4.88936 6.54548 4.33733 7.01865 3.86417C7.49181 3.391 8.04384 3.15442 8.67473 3.15442H21.9234C22.2388 3.15442 22.5477 3.22014 22.85 3.35157C23.1523 3.48301 23.4086 3.65387 23.6189 3.86417L30.8347 11.08C31.045 11.2902 31.2158 11.5465 31.3473 11.8488C31.4787 12.1511 31.5444 12.46 31.5444 12.7755V32.333C31.5444 32.9639 31.3078 33.5159 30.8347 33.9891C30.3615 34.4622 29.8095 34.6988 29.1786 34.6988H8.67473ZM21.7262 11.6714C21.7262 12.0131 21.8379 12.2957 22.0614 12.5192C22.2848 12.7426 22.5674 12.8543 22.9091 12.8543H29.1786L21.7262 5.52025V11.6714ZM17.1129 24.7229L13.8007 21.4107C13.6955 21.3056 13.5773 21.2267 13.4458 21.1742C13.3144 21.1216 13.183 21.0953 13.0515 21.0953C12.9201 21.0953 12.7886 21.1282 12.6572 21.1939C12.5258 21.2596 12.4075 21.345 12.3023 21.4502C12.092 21.6605 11.9869 21.9168 11.9869 22.2191C11.9869 22.5214 12.092 22.7777 12.3023 22.988L16.2848 26.9704C16.4163 27.1019 16.5477 27.1939 16.6791 27.2465C16.8106 27.299 16.9551 27.3253 17.1129 27.3253C17.2706 27.3253 17.4152 27.299 17.5466 27.2465C17.678 27.1939 17.8095 27.1019 17.9409 26.9704L25.3933 19.5181C25.6036 19.3078 25.7087 19.0515 25.7087 18.7492C25.7087 18.4469 25.6036 18.1906 25.3933 17.9803C25.183 17.77 24.9267 17.6648 24.6244 17.6648C24.3221 17.6648 24.0658 17.77 23.8555 17.9803L17.1129 24.7229Z"
                fill={
                  selected === wflow_status["Completed"]
                    ? "#fff"
                    : "rgba(17, 20, 18, 0.6)"
                }
              />
            </svg>
            <h3>
              {" "}
              {orderCounts[wflow_status["Completed"]]
                ? orderCounts[wflow_status["Completed"]]
                : 0}
            </h3>
          </div>
          <div>Completed Orders</div>
        </Tabs>

        <Tabs
          onClick={() => {
            setSelected(wflow_status["Pending Cancellation"]);
            setPage(0);
            setRowsPerPage(5);
          }}
          style={{
            backgroundColor:
              selected === wflow_status["Pending Cancellation"]
                ? "rgb(42, 130, 253)"
                : "#FFF",
            color:
              selected === wflow_status["Pending Cancellation"]
                ? "white"
                : "rgba(17, 20, 18, 0.6)",
            border:
              selected === wflow_status["Pending Cancellation"]
                ? "1px solid rgb(42, 130, 253)"
                : "1px solid rgba(17, 20, 18, 0.6) ",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100px",
              justifyContent: "space-evenly",
            }}
          >
            <WarningIcon fontSize="large" />
            <h3>
              {" "}
              {orderCounts[wflow_status["Pending Cancellation"]]
                ? orderCounts[wflow_status["Pending Cancellation"]]
                : 0}
            </h3>
          </div>
          <div>Process Cancellation</div>
        </Tabs>

        <Tabs
          onClick={() => {
            setSelected(wflow_status["Cancelled"]);
            setPage(0);
            setRowsPerPage(5);
          }}
          style={{
            backgroundColor:
              selected === wflow_status["Cancelled"]
                ? "rgb(42, 130, 253)"
                : "#FFF",
            color:
              selected === wflow_status["Cancelled"]
                ? "white"
                : "rgba(17, 20, 18, 0.6)",
            border:
              selected === wflow_status["Cancelled"]
                ? "1px solid rgb(42, 130, 253)"
                : "1px solid rgba(17, 20, 18, 0.6) ",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100px",
              justifyContent: "space-evenly",
            }}
          >
            <CancelIcon fontSize="large" />
            <h3>
              {" "}
              {orderCounts[wflow_status["Cancelled"]]
                ? orderCounts[wflow_status["Cancelled"]]
                : 0}
            </h3>
          </div>
          <div>Cancelled Orders</div>
        </Tabs>
      </TabsContainer>

      <SearchContent>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSearch(null);
          }}
          style={{ width: "100%" }}
        >
          <TextFieldSearch
            fullWidth
            value={searchInputText}
            placeholder="Enter your search keyword above and press the Enter key to initiate the search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ fontSize: "18px", color: "#174a84" }} />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setInputText(e.target.value);
              if (e.target.value === "") {
                handleSearch(e.target.value);
              }
            }}
            // onBlur={handleSearch}
            variant="outlined"
          />
        </form>
      </SearchContent>

      {/**
       * Div that has all the filters
       */}
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        <FiltersComponent
          allProps={{
            isSubmitting,
            filters,
            customerList,
            customerFilter,
            setCustomerFilter,
            taskStatesFilter,
            setTaskStatesFilter,
            dateFilter,
            setDateFilter,
            startDateFilter,
            setStartDateFilter,
            endDateFilter,
            setEndDateFilter,
            /* regionsFilter, setRegionsFilter, */
            yubikeyTypesFilter,
            setYubikeyTypesFilter,

            cityFilter,
            setCityFilter,
            cityList,

            stateProvinceFilter,
            setStateProvinceFilter,
            stateProvinceList,

            countryFilter,
            setCountryFilter,
            countryList,

            submitUpdates,
            updates,
          }}
        />
      </div>

      <div>
        <TableComponent
          order={order}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          page={page}
          setPage={setPage}
          orderBy={orderBy}
          handleRequestSort={handleRequestSort}
          total={orderCounts[selected]}
          data={searchResults}
          headCellData={headCells[`${1}`]}
          actionComponent={tableActions[`${1}`]}
          isBulkDelete={isBulkDelete}
          setIsBulkDelete={setIsBulkDelete}
          handleSelectedItemForDelete={handleSelectedItemForDelete}
          setSelectedOrders={setSelectedOrders}
          selectedOrders={selectedOrders}
          customerFilter={customerFilter}
          updates={updates}
          setUpdates={setUpdates}
          handleReload={reload}
          // conversation modal action:
          viewConversations={handleViewConversations} //initial
          // countries modal action:
          viewCountries={handleViewCountries}
        />
      </div>
      {/* Conversation Module */}
      <div>
        {/* Conversation Modal */}
        <Modal
          className="mui-modal"
          sx={{ zIndex: 1000 }}
          open={openConversationModal}
          onClose={toggleConversationModal}
        >
          <div className="conversation-modal">
            <MUICard
              style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
              title={"Conversations"}
              icon={<ForumIcon />}
              action={
                <IconButton onClick={toggleConversationModal}>
                  <CloseIcon />
                </IconButton>
              }
            >
              {comments.length > 0 ? (
                comments.map((comment) => (
                  <div key={comment.UUID}>
                    <ConversationCard
                      displayName={comment.createdBy.name}
                      email={comment.createdBy.email}
                      subtitle={comment.createdOn}
                      message={comment.value}
                    />
                    <div style={{ height: "12px" }}></div>
                  </div>
                ))
              ) : (
                <div style={{ width: "100%", textAlign: "center" }}>
                  {fetchingComments ? (
                    <CircularProgress />
                  ) : (
                    <Typography>No Comments Available</Typography>
                  )}
                </div>
              )}
            </MUICard>
            <MUICard
              style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
            >
              <Grid spacing={1} container>
                <Grid sm={11} item>
                  <TextField
                    value={commentText}
                    onChange={(e) => setCommentText(e.target.value)}
                    size="small"
                    placeholder="Add comment..."
                    fullWidth
                    multiline
                    disabled={isAddComment}
                  />
                </Grid>
                <Grid sm={1} item>
                  <LoadingButton
                    size="large"
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="button"
                    onClick={handleSubmitComment}
                    loading={isAddComment}
                    disabled={commentText ? false : true}
                  >
                    <SendIcon />
                  </LoadingButton>
                </Grid>
              </Grid>
            </MUICard>
          </div>
        </Modal>
      </div>

      {/* Countries Module */}
      <div>
        <Modal
          open={openCountriesModal}
          onClose={toggleCountriesModal}
          className="mui-modal"
        >
          <div className={"countries-modal"}>
            <MUICard>
              <Grid alignItems={"center"} spacing={1} container>
                <Grid sm={8} item>
                  <TextField
                    value={selectedCountry.country}
                    onChange={(e) =>
                      setSelectedCountry((prev) => ({
                        ...prev,
                        country: e.target.value,
                      }))
                    }
                    disabled={isCountryUpdating}
                    size="small"
                    fullWidth
                    select
                  >
                    {countryList.map((country) => (
                      <MenuItem key={country.id} value={country.code}>
                        {country.name} ({country.code})
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid sm={4} item>
                  <LoadingButton
                    size="large"
                    variant="contained"
                    onClick={handleUpdateCountry}
                    loading={isCountryUpdating}
                    fullWidth
                  >
                    Update
                  </LoadingButton>
                </Grid>
              </Grid>
            </MUICard>
          </div>
        </Modal>
      </div>
    </Root>
  );
}

function mapStateToProps(state) {
  //console.log(' state.orderReducers.selectedData ', JSON.stringify(state.orderReducers.selectedData))

  return {
    selectedData: state.orderReducers.selectedData || [],
    orderCounts: state.orderReducers.orderCounts || {},
    customerList: state.orderReducers.customerList || [],
    cityList: state.orderReducers.cityList || [],
    stateProvinceList: state.orderReducers.stateProvinceList || [],
    countryList: state.orderReducers.countryList || [],
  };
}
const mapDispatchToProps = {
  getOrderListByStatus,
  getOrdersCount,
  getCustomerList,
  getCityList,
  getStateProvinceList,
  getCountryList,
  updateOrders,

  //add comments:
  addComment,
  getComments,
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderList);
