import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@mui/system";
import OrderList from "./components/orders/orderList";
import OrderDetails from "./components/orders/orderDetails";
import PDFManipulationScreen from "./components/orders/pdfManipulation";
import Login from "./components/googleAuthLogin/login";
import Logout from "./components/googleAuthLogin/logout";
import PersistLogin from "./components/PersistLogin";
import RequireAuth from "./components/googleAuthLogin/requireAuth";
import { Route, Routes, useNavigate } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import axios from "axios";
import "./App.css";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";


function App(props) {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const ROLES = {
    User: "1",
  };
  useEffect(() => {
    if (auth?.accessToken) {
      window.sessionStorage.setItem(
        "accessToken",
        `Bearer` + " " + auth?.accessToken
      );
    }
  }, [auth]);

  return (
    <div className="App">
      <Routes>
        <Route path="/login" element={<Login {...props} />} />
        <Route element={<PersistLogin />}>
          {/* <Route element={<Logout />} /> */}

          {/* <Route element={<Logout />} /> */}
          <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
            <Route path="/" element={<OrderList {...props} />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
            <Route
              path="/orderDetails/:uuid"
              element={<OrderDetails {...props} />}
            />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
            <Route path="/pdf" element={<PDFManipulationScreen {...props} />} />
          </Route>
        </Route>
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}
export default App;
