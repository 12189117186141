import axios from "axios";
import { USER_ROLE, USER_DETAILS, CURRENT_USER_ROLE } from "./actionTypes";
// import invokeAPI from "../config/apiConfiguration";
// import apiEndpoints from "../config/backendConfig";

export const setUserRole = (values) => (dispatch) => {
  dispatch({
    type: USER_ROLE,
    payload: values,
  });
};

export const setCurrentRole = (values) => (dispatch) => {
  dispatch({
    type: CURRENT_USER_ROLE,
    payload: values,
  });
};
export const setUserDetail = (values) => (dispatch) => {
  dispatch({
    type: USER_DETAILS,
    payload: values,
  });
};

//Admin Settings
