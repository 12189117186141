import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@mui/system";
import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import { connect } from "react-redux";
//import { validateAddress } from "../../actions/authAction";
import { country_codes } from "../orders/constants";
import axios from "axios";
import { PhoneNumberUtil } from "google-libphonenumber";
import CustomizedSnackbars from "./triggerSnacbar";
import theme from "../../theme";
import TextFieldComponent from "./textField";

const TextFieldContent = styled(TextField)`
  //   background-color: #red !important;
  & input {
    padding: 10px;
  }
`;
const Label = styled("div")`
  display: flex;
  flex-direction: row;
  color: #333c44;
  margin-bottom: 10px;
`;
const AddressComponent = (props) => {
  const { order/* , validateAddress */ } = props;
  const [status, setStatusBase] = useState("");

  console.log(order, "det");

  /*   const handleAddressValidation = async () => {
      try {
        let payload = {
          addressesToValidate: [
            {
              address: {
                streetLines: [
                  customerDetails.shipping_address.split("\n")[0].trim(),
                ],
                city: order.shipping_address.split("\n")[1],
                countryCode: country_codes[`${customerDetails.customer_country}`],
              },
            },
          ],
        };
        let headers = {
          "Content-type": "application/json",
          "X-locale": "en_US",
          Authorization: sessionStorage.getItem("addressApiAuthToken"),
        };
        const response = await axios.post(
          `${process.env.REACT_APP_ADDRESS_VALIDATION_URL}`,
          payload,
          { headers: headers },
          { withCredentials: true }
        );
  
        if (response) {
          if (response.status === 200) {
            if (
              response.data?.output?.resolvedAddresses[0]?.customerMessages.length
            ) {
              setStatusBase({
                severity: "error",
                messageText: "Sorry! Address is not found",
              });
            } else {
              setStatusBase({
                severity: "success",
                messageText: "Address is validated",
              });
            }
          }
        }
      } catch (e) {
        if (e?.response?.status === 500) {
          setStatusBase({
            severity: "error",
            messageText: "Internal server error",
          });
        }
        if (e?.response?.status === 503) {
          setStatusBase({
            severity: "error",
            messageText:
              "The service is currently unavailable and we are working to resolve the issue. We apologize for any inconvenience. Please check back at a later time.",
          });
        }
        if (e?.response?.status === 403) {
          setStatusBase({
            severity: "error",
            messageText: "Internal server error",
          });
        }
      }
    }; */

  /* 
    const handlePhoneNumberValdation = () => {
      try {
        let phoneUtil = PhoneNumberUtil.getInstance();
        const number = phoneUtil.parseAndKeepRawInput(
          `${customerDetails.contact_phone}`,
          `${country_codes[`${customerDetails.customer_country}`]}`
        );
        console.log(number, "numbwr");
        const res = phoneUtil.isValidNumberForRegion(
          number,
          `${country_codes[`${customerDetails.customer_country}`]}`
        );
        if (res === true) {
          setStatusBase({
            severity: "success",
            messageText: "Phone number is validated",
          });
        } else {
          setStatusBase({
            severity: "error",
            messageText: "Invalid phone number!",
          });
        }
      } catch (e) {
        setStatusBase({
          severity: "error",
          messageText: "Please contact technical support",
        });
      }
    }; */

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "10px",
            width: "50%",
          }}
        >
          <TextFieldComponent
            label="To"
            value={order.name}
          />
          <br />

          <TextFieldComponent
            label="Address"
            value={order.address}
            disabled
          />
          <br />
          <TextFieldComponent
            label="Address 2 (If Applicable)"
            value={order.address_2}
          />
          <br />
          <TextFieldComponent
            label="City"
            value={order.city}
          />
          <br />
          <TextFieldComponent
            label="State / Province"
            value={order.state_province}
          />
          <br />
          <TextFieldComponent
            label="Zipcode"
            value={order.zipcode}
          />
          <br />

        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "10px",
            width: "50%",
          }}
        >
          <TextFieldComponent
            label="Country"
            value={order.country}
          />
          <br />

          {/*  <TextFieldComponent
            label="Region"
            value={order.region}
          />
          <br /> */}

          <TextFieldComponent
            label="Phone Number"
            value={order.phone}
          />
          <br />

          {/* <button onClick={handleAddressValidation} id="validateAddress">
            Validate Address
          </button>
          <button onClick={handlePhoneNumberValdation}>
            Verify PhoneNumber
          </button> */}
        </div>
      </div>
      {/*   {status ? (
        <CustomizedSnackbars
          severity={status.severity}
          messageText={status.messageText}
          setStatusBase={setStatusBase}
          status={status}
        />
      ) : null} */}
    </div>
  );
};
function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = { /* validateAddress */ };
export default connect(mapStateToProps, mapDispatchToProps)(AddressComponent);
