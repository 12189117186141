import {
    GET_CUSTOMER_LIST,
} from "../actions/actionTypes";
import initialState from "./initialState";

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case GET_CUSTOMER_LIST:
            return {
                ...state,
                customerList: action.payload,
            };
        default:
            return state;
    }
}
